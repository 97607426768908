export const FIELD_OPTIONS_ENDPOINTS = {
    international_factors: `/v1/fieldsGetter/retrieveInternationalFactors`,
    currencies: `/v1/fieldsGetter/retrieveCurrencies`,
    national_factors: `/v1/fieldsGetter/retrieveNationalFactors`,
    season_by_months: `/v1/fieldsGetter/retrieveSeasonByMonths`,
    seasons: `/v1/fieldsGetter/retrieveSeasons`,
    international_allowance_by_roles: `/v1/fieldsGetter/retrieveInternationalAllowanceByRoles`,
    bill_accounts: `/v1/fieldsGetter/retrieveBillAccounts`,
    account_types: '/v1/fieldsGetter/retrieveAccountTypes',
    metropolitan_region_trip_constants: `/v1/fieldsGetter/retrieveMetropolitanRegionTripConstants`,
    national_trip_constants: `/v1/fieldsGetter/retrieveNationalTripConstants`,
    vehicle_categories: `/v1/fieldsGetter/retrieveVehicleCategories`,
    vehicle_types: `/v1/fieldsGetter/retrieveVehicleTypes`,
    vehicle_statuses: `/v1/fieldsGetter/retrieveVehicleStatuses`,
    benefit_centers: `/v1/fieldsGetter/retrieveBenefitCenters`,
    organizational_units: `/v1/fieldsGetter/retrieveOrganizationalUnits`,
    system_roles: `/v1/fieldsGetter/retrieveSystemRoles`,
    expense_categories: '/v1/fieldsGetter/retrieveExpenseCategories',
    projects: '/v1/fieldsGetter/retrieveProjects',
}