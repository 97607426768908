import React, { useRef, useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom';
import { useToast } from '../../context/useToast';
import 'dayjs/locale/es';
import { useAuthStatus } from '../../hooks/useAuthStatus';
import BlockScreen from '../blockScreen/blockScreen';
import showExactDate from '../../utils/showExactDate';
import { useNavigate } from 'react-router-dom';
import Loader from '../../elements/loader';
import dayjs from 'dayjs';
import 'react-toastify/dist/ReactToastify.css';
import { TravelAllowanceRequestToShow, TravelAllowanceRequestDetails,
InternationalFactor, NationalFactor, SeasonByMonth, Season,
InternationalAllowanceByRole, MetropolitanRegionTripConstant,
NationalTripConstant, OrganizationalUnit, BenefitCenter, TravelAllowanceRequestPermissions } from '../../types/fields';
import performApiRequest from '../../utils/performApiRequest';
import { useDataForEndpoint } from '../../context/dataProvider';
import GenericReviewModal from '../../elements/genericReviewModal';
import { reviewModalConfig } from '../../utils/reviewModalConfig';
import WarningIcon from '@mui/icons-material/Warning';
import ConvertDateToDayMonthYear from '../../utils/convertDateToDayMonthYear';
import { useAccessPermissions } from '../../hooks/useAccessPermission';
import FilesModal from '../../elements/filesModal';
import UploadTravelAllowanceRequestJustificationFileModal from '../../elements/uploadTravelAllowanceRequestJustificationFileModal';
import { API_ENDPOINTS } from '../../utils/endpoints';
import ChangeCurrencyRateInput from '../../elements/changeCurrencyRateInput';
import VehicleTracker from '../../utils/vehicleTracker';
import { List, ListItem, ListItemText } from '@mui/material';


export type Vehicle = {
    id: number,
    vehicle_type_id: string;
    vehicle_plate: string;
    brand: string;
    model: string;
    version: string;
    fabrication_year: string;
    acquisition_date: string;
    vehicle_status_id: string;
    description: string;
    traction: string;
    last_maintenance_date: string;
    vehicle_image: string;
    vehicle_status_name?: string;
    vehicle_type_name?: string;
}

interface ModalProps {
    title: string;
    message: string;
    actionName: string;
    endpoint: string;
    isCommentMandatory: boolean;
    onSend: (comment: string) => void;
}

interface WarningMessage {
    id: number[];
    message: string
}

type FileDataType = {
    id: number;
    file_url: string;
    request_id: number;
    document_number: string;
    expense_date: string;
    total_amount: number;
    currency_id: string;
    business_rut: string;
    commentary: string;
};

export default function ShowTravelAllowanceRequest () {
    const isAuthenticated = useAuthStatus();
    const [travelAllowanceRequest, setTravelAllowanceRequest] = useState<TravelAllowanceRequestToShow>()
    const [travelAllowanceRequestDetails, setTravelAllowanceRequestDetails] = useState<TravelAllowanceRequestDetails>([])
    const [permissions, setPermissions] = useState<TravelAllowanceRequestPermissions>();
    const [warningMessage, setWarningMessage] = useState<WarningMessage>();
    const nationalFactors = useDataForEndpoint<NationalFactor[]>('national_factors');
    const seasonByMonths = useDataForEndpoint<SeasonByMonth[]>('season_by_months');
    const seasons = useDataForEndpoint<Season[]>('seasons');
    const organizationalUnits = useDataForEndpoint<OrganizationalUnit[]>('organizational_units');
    const benefitCenters = useDataForEndpoint<BenefitCenter[]>('benefit_centers');
    const internationalAllowanceByRoles = useDataForEndpoint<InternationalAllowanceByRole[]>('international_allowance_by_roles')
    const metropolitanRegionTripConstants = useDataForEndpoint<MetropolitanRegionTripConstant[]>('metropolitan_region_trip_constants');
    const nationalTripConstants = useDataForEndpoint<NationalTripConstant[]>('national_trip_constants')
    const internationalFactors = useDataForEndpoint<InternationalFactor[]>('international_factors');
    const [isLoading, setIsLoading] = useState(true);
    const { id } = useParams();
    const navigate = useNavigate();
    const [availableVehicles, setAvailableVehicles] = useState<Vehicle[]>([]);
    const [proposedVehicleId, setProposedVehicleId] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isChangeCurrencyRateModalOpen, setChangeCurrencyRateModalOpen] = useState(false);
    const [isFilesBlockOpen, setFilesBlockOpen] = useState(false);
    const [isFileUploadModalOpen, setFileUploadModalOpen] = useState(false);
    const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
    const { showSuccessToast, showErrorToast } = useToast();
    const modalBackdropRef = useRef<HTMLDivElement>(null);
    const deleteRequestEndpoint = `/v1/travelAllowanceRequestController/deleteRequest`;
    const handleEditVehicleEndpoint = `/v1/travelAllowanceRequestController/changeVehicleRequested`;
    const { accessPermissions } = useAccessPermissions();
    const [currentFileToEdit, setCurrentFileToEdit] = useState<FileDataType | undefined>(undefined);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [error, setError] = useState<Error | null>(null);
    const [inputRateValue, setInputRateValue] = useState('');

    const handleInputChange = (value: string) => {
      setInputRateValue(value);
    };

    const handleEditFile = (file: any) => {
        setCurrentFileToEdit(file);
        openFileUploadModal();
    };

    const closeModal = () => {
      setIsModalOpen(false);
    };

    const closeWarningModal = () => {
        setIsWarningModalOpen(false);
    };

    const closeReviewModal = () => {
        setReviewModalOpen(false);
    };

    const closeFilesBlock = () => {
        setFilesBlockOpen(false);
    }

    const openFilesBlock = () => {
        setFilesBlockOpen(true);
    };

    const openFileUploadModal = () => {
        setFileUploadModalOpen(true);
    }

    const closeFileUploadModal = () => {
        setFileUploadModalOpen(false);
        fetchRequest();
    }

    const openChangeCurrencyRateModal = () => {
        setChangeCurrencyRateModalOpen(true);
    }

    const [isReviewModalOpen, setReviewModalOpen] = useState(false);
    const [reviewModalProps, setReviewModalProps] = useState<ModalProps>({
      title: "",
      message: "",
      actionName: "",
      endpoint: "",
      isCommentMandatory: false,
      onSend: () => {},
    });

    const openReviewModal = (actionType: keyof typeof reviewModalConfig, requestId: number) => {
        const actionConfig = reviewModalConfig[actionType];
    
        if (!actionConfig) {
            window.alert("No existe esta opción.");
            return;
        }
    
        setReviewModalProps({
            ...actionConfig,
            onSend: async (comment: string) => {
                try {
                    const requestOptions = {
                        method: 'PATCH',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        data: JSON.stringify({ request_id: requestId, comment }),
                    };
    
                    const response = await performApiRequest(actionConfig.endpoint, requestOptions);
                    if (response.status !== 201) throw new Error('Ocurrió un error.');
    
                    showSuccessToast(`Se actualizó exitosamente.`);
                    navigate('/allTravelAllowanceRequests');
                } catch (error) {
                    showErrorToast(`Ocurrió un error en: ${actionConfig.actionName}`);
                    console.error(error);
                } finally {
                    closeReviewModal();
                }
            },
        });
    
        setReviewModalOpen(true);
    };
      
  
    const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (event.target === modalBackdropRef.current) {
        closeModal();
      }
    };

    const sendChangeOfCurrencyRateRequest = async (rate: string) => {
        const requestOptions = {
            method: 'PATCH',
            data: { request_id: travelAllowanceRequest?.id, rate }
        };

        await performApiRequest(API_ENDPOINTS.editCurrencyRateFromTravelAllowanceRequest, requestOptions)
            .then((response) => {
                setChangeCurrencyRateModalOpen(false);
                if (response.status === 204) {
                    window.location.reload();
                    showSuccessToast('Se actualizó la tasa de cambio correctamente.');
                    fetchRequest();
                };
            })
            .catch((error) => {
                showErrorToast(`Ocurrió un error al actualizar la tasa de cambio.`);
                console.error(error);
            })
            .finally(() => {
                closeModal();
        });
    }

    useEffect(() => {
        const allData = [
          nationalFactors,
          seasonByMonths,
          seasons,
          organizationalUnits,
          benefitCenters,
          internationalAllowanceByRoles,
          metropolitanRegionTripConstants,
          nationalTripConstants,
          internationalFactors,
        ];
        
        const isAnyDataUndefined = allData.some(data => data === undefined);
    
        setIsLoading(isAnyDataUndefined);
    }, [nationalFactors, seasonByMonths, seasons, organizationalUnits, benefitCenters, internationalAllowanceByRoles, metropolitanRegionTripConstants, nationalTripConstants, internationalFactors]); // Add other dependencies as needed
    
    const fetchRequest = useCallback(async () => {
        setIsLoading(true);
        setError(null);
      
        try {
          const endpoint = `/v1/travelAllowanceRequestController/particularTravelAllowanceRequest/${id}`;
          const response = await performApiRequest(endpoint, { method: 'GET' });
          setTravelAllowanceRequest(response.data.travelRequest);
          setTravelAllowanceRequestDetails(response.data.associatedTripRequest);
          setPermissions(response.data.permissions);
          setWarningMessage(response.data.warningMessage);
          const vehicleEndpoint = `/v1/vehicleController/availableVehicles?startDate=${response.data.travelRequest.travel_start_date}&endDate=${response.data.travelRequest.travel_end_date}`;
          const availableVehiclesResponse = await performApiRequest(vehicleEndpoint, { method: 'GET' });
          setAvailableVehicles(availableVehiclesResponse.data);
        } catch (error: unknown) {
            if (error instanceof Error) {
                setError(error);
            } else {
                setError(new Error('An unexpected error occurred'));
            }
        }
      
        setIsLoading(false);
    }, [id]);

    useEffect(() => {
        if (isAuthenticated) {
            fetchRequest();
        }
    }, [isAuthenticated, fetchRequest]);

    function getNationalFactor () {
        const monthId = dayjs(travelAllowanceRequest?.travel_start_date).month() + 1;
        const seasonId = seasonByMonths.find(month => month.id === monthId)?.season_id ?? 1
        return (
            <div className="text-center text-gray-700 font-semibold">x 
                {(seasons.find(season => season.id === seasonId)?.season_name === 'Temporada alta')?
                nationalFactors.find(constant => constant.id === Number(travelAllowanceRequestDetails.destination_region_id))?.high_season_factor :
                nationalFactors.find(constant => constant.id === Number(travelAllowanceRequestDetails.destination_region_id))?.low_season_factor}
            </div>
        )
    }

    function getNationalFactorValue () : number {
        const monthId = dayjs(travelAllowanceRequest?.travel_start_date).month() + 1;
        const seasonId = seasonByMonths.find(month => month.id === monthId)?.season_id ?? 1
        const nationalFactor = (seasons.find(season => season.id === seasonId)?.season_name === 'Temporada alta') ?
            nationalFactors.find(constant => constant.id === Number(travelAllowanceRequestDetails.destination_region_id))?.high_season_factor :
            nationalFactors.find(constant => constant.id === Number(travelAllowanceRequestDetails.destination_region_id))?.low_season_factor ?? 1;
        return nationalFactor ?? 1;
    }

    function getSeasonName () : string {
        const monthId = dayjs(travelAllowanceRequest?.travel_start_date).month() + 1;
        const seasonId = seasonByMonths.find(month => month.id === monthId)?.season_id ?? 1
        const season_name = seasons.find(season => season.id === seasonId)?.season_name ?? 'Temporada baja';
        return season_name;
    }

    const handleEdit = (requestData: TravelAllowanceRequestToShow, requestDetails: TravelAllowanceRequestDetails) => {
        navigate('/editTravelAllowanceRequest', { state: { requestData, requestDetails } });
    };

    const handleEditVehicle = async (vehicle_id: number) => {
        if (isAuthenticated) {
            try {
                const requestOptions = {
                    method: 'PATCH',
                    data: {
                        request_id: travelAllowanceRequest?.id,
                        requested_vehicle_id: vehicle_id
                    }
                }

                const response = await performApiRequest(`${handleEditVehicleEndpoint}`, requestOptions);
                 if (response.status === 200) {
                    showSuccessToast('Solicitud editada correctamente', {
                        autoClose: 3000
                    });
                    navigate('/allTravelAllowanceRequests'); 
                 }
            } catch (err: any) {
                showErrorToast(`Error: ${err}`);
            }
        }
    };

    const handleDelete = async (id: any) => {
        const endpoint = `${deleteRequestEndpoint}/${id}`
        const requestOptions = {
            method: 'PATCH'
        }
        if (window.confirm("Presiona Aceptar si estás seguro de eliminar la solicitud.")) {
            try {
                const response = await performApiRequest(endpoint, requestOptions);
                if (response.status === 204) {
                    showSuccessToast('Eliminación exitosa');
                    navigate('/');
                }
            } catch (err: any) {
                showErrorToast(`Error: ${err.message}`)
            }
        }
    };

    if (!isAuthenticated) {
        return <BlockScreen/>
    }

    if (isLoading) {
        return <Loader/>
    }

    if (!travelAllowanceRequest || !travelAllowanceRequestDetails) {
        return <Loader/>
    }

    return (
        <div className="justify-center items-center text-center">
            {(warningMessage?.id?.length ?? 0) > 0 ? (
                    <button 
                        className="flex items-center justify-center p-2 bg-black text-white border-2 border-yellow-500 rounded-md m-2 text-center text-md font-custom" 
                        onClick={() => setIsWarningModalOpen(true)}
                    >
                    <WarningIcon style={{ fontSize: 30, color: "yellow" }}/>
                    El funcionario que requiere el viático ya tiene una o más solicitudes de viático en el mismo rango de fechas. Solicitudes: {warningMessage?.id?.toString()}
                </button>
            ) : (null)}
        <div className="flex flex-col md:flex-row p-4 bg-white shadow rounded-lg">
        <div className="mb-6">
            <h2 className="text-gray-700 text-lg font-bold mb-4">Datos del receptor del viático - Solicitud No. {travelAllowanceRequest.id} - Realizada el {ConvertDateToDayMonthYear(travelAllowanceRequest.createdAt)}</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                    <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_employee_name">
                        Funcionario que envió el formulario
                    </label>
                    <p className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                        {travelAllowanceRequest.formCompleterUserData.names} {travelAllowanceRequest.formCompleterUserData.ap_pat}
                    </p>
                </div>

                <div>
                    <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_employee_name">
                        Funcionario que requiere el viático
                    </label>
                    <p className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                        {travelAllowanceRequest.requestingUserData.names} {travelAllowanceRequest.requestingUserData.ap_pat}
                    </p>
                </div>

                <div>
                    <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_employee_name">
                        RUT del funcionario que requiere el viático
                    </label>
                    <p className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                        {travelAllowanceRequest.requestingUserData.rut}-{travelAllowanceRequest.requestingUserData.dv_rut}
                    </p>
                </div>

                <div>
                    <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_employee_name">
                        Estado de la solicitud
                    </label>
                    <p className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                        {travelAllowanceRequest.high_order_status}
                    </p>
                </div>

                <div>
                    <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_employee_name">
                        Unidad solicitante
                    </label>
                    <p className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                        {organizationalUnits.find(unit => unit.organizational_unit_id === travelAllowanceRequest.requesting_unit_id)?.organizational_unit_name ?? 'Información no disponible.'}
                    </p>
                </div>

                <div>
                    <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_employee_name">
                        Centro de beneficio
                    </label>
                    <p className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                        {travelAllowanceRequest.benefit_center_prc_code} - {benefitCenters.find(center => center.prc_code === travelAllowanceRequest.benefit_center_prc_code)?.benefit_center_name ?? 'Información no disponible.'}
                    </p>
                </div>

                <div>
                    <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_employee_name">
                        Número de proyecto
                    </label>
                    <p className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                        {(travelAllowanceRequest.project_number ? travelAllowanceRequest.project_number : 'No informado.')}
                    </p>
                </div>

                <div>
                    <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_employee_name">
                        Forma de pago
                    </label>
                    <p className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                        {(travelAllowanceRequest.requires_deposit ? 'Requiere depósito.' : 'No requiere depósito.')}
                    </p>
                </div>

                {(travelAllowanceRequest.requires_deposit) && (
                    <div>
                        <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_employee_name">
                            Nombre del banco
                        </label>
                        <p className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                            {travelAllowanceRequest.bank_name}
                        </p>
                    </div>
                )}

                {(travelAllowanceRequest.requires_deposit) && (
                    <div>
                        <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_employee_name">
                            Tipo de cuenta del banco
                        </label>
                        <p className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                            {travelAllowanceRequest.account_type}
                        </p>
                    </div>
                )}

                {(travelAllowanceRequest.requires_deposit) && (
                    <div>
                        <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_employee_name">
                            Número de cuenta del banco
                        </label>
                        <p className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                            {travelAllowanceRequest.account_number}
                        </p>
                    </div>
                )}

                <div>
                    <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_employee_name">
                        Tipo de viático
                    </label>
                    <p className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                        {travelAllowanceRequest.viatic_type}
                    </p>
                </div>

                <div>
                    <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_employee_name">
                        Vehículo solicitado
                    </label>
                    <p className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                        {travelAllowanceRequest.vehicle ? travelAllowanceRequest.vehicle.vehicle_plate : 'No se solicitó vehículo.'}
                    </p>
                </div>

                <div>
                    <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_employee_name">
                        Fecha de inicio y término del viaje
                    </label>
                    <p className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                        Desde {showExactDate(travelAllowanceRequest.travel_start_date)} hasta {showExactDate(travelAllowanceRequest.travel_end_date)}
                    </p>
                </div>

                <div>
                    <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_employee_name">
                        Días de duración del viaje
                    </label>
                    <p className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                        {travelAllowanceRequest.days_spent} días
                    </p>
                </div>
                <div>
                    <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_employee_name">
                        Motivos del viaje
                    </label>
                    <p className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                        {travelAllowanceRequest.trip_reason}
                    </p>
                </div>
            </div>

            <div className="bg-sectionBarBackgroundColor shadow-md rounded px-8 pt-6 pb-8 mb-10 mt-10">
                    <h2 className="block text-gray-700 text-md font-bold mb-10 text-left">
                        Cálculo del viático
                    </h2>

                    { (travelAllowanceRequest.viatic_type === 'Nacional - Región Metropolitana') && (
                            <div className="w-full content-center">
                                <div className="grid md:grid-cols-4 gap-4">
                                    <div>
                                        <label htmlFor="breakfast_days_only" className="block text-gray-700 text-sm font-semibold mr-5">
                                            Días de solo desayuno
                                        </label>
                                    </div>
                                    <div>
                                        <p className="text-sm text-center shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                                            {travelAllowanceRequestDetails.breakfast_days_only}
                                        </p>
                                    </div>
                                    <div className="text-center text-gray-700 font-semibold">x {metropolitanRegionTripConstants.find((constant) => constant.item === 'breakfast_days_only')?.uf_amount || 0} UF</div>
                                    <div className="text-center text-gray-700 font-semibold">= {(travelAllowanceRequestDetails.breakfast_days_only * (metropolitanRegionTripConstants.find((constant) => constant.item === 'breakfast_days_only')?.uf_amount ?? 0)).toFixed(3)} UF</div>

                                    <div>
                                        <label htmlFor="lunch_days_only" className="block text-gray-700 text-sm font-semibold mr-5">
                                            Días de solo almuerzo
                                        </label>
                                    </div>                                           
                                    <div>
                                        <p className="text-sm text-center shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                                            {travelAllowanceRequestDetails.lunch_days_only}
                                        </p>
                                    </div>
                                    <div className="text-center text-gray-700 font-semibold">x {metropolitanRegionTripConstants.find((constant) => constant.item === 'lunch_days_only')?.uf_amount || 0} UF</div>
                                    <div className="text-center text-gray-700 font-semibold">= {(travelAllowanceRequestDetails.lunch_days_only * (metropolitanRegionTripConstants.find((constant) => constant.item === 'lunch_days_only')?.uf_amount ?? 0)).toFixed(3)} UF</div>
                                    
                                    <div>
                                        <label htmlFor="dinner_days_only" className="block text-gray-700 text-sm font-semibold mr-5">
                                            Días de solo cena
                                        </label>
                                    </div>
                                    <div>
                                        <p className="text-sm text-center shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                                            {travelAllowanceRequestDetails.dinner_days_only}
                                        </p>
                                    </div>
                                    <div className="text-center text-gray-700 font-semibold">x {metropolitanRegionTripConstants.find((constant) => constant.item === 'dinner_days_only')?.uf_amount || 0} UF</div>
                                    <div className="text-center text-gray-700 font-semibold">= {(travelAllowanceRequestDetails.dinner_days_only * (metropolitanRegionTripConstants.find((constant) => constant.item === 'dinner_days_only')?.uf_amount ?? 0)).toFixed(3)} UF</div>

                                    <div>
                                        <label htmlFor="meal_vouchers_discount" className="block text-gray-700 text-sm font-semibold mr-5">
                                            Descuentos por vale de colación
                                        </label>
                                    </div>
                                    <div>
                                        <p className="text-sm text-center shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                                            {travelAllowanceRequestDetails.meal_vouchers_discount}
                                        </p>
                                    </div>
                                    <div className="text-center text-gray-700 font-semibold">x {metropolitanRegionTripConstants.find((constant) => constant.item === 'meal_voucher_discount_quantity')?.uf_amount || 0} UF</div>
                                    <div className="text-center text-gray-700 font-semibold">= - {(travelAllowanceRequestDetails.meal_vouchers_discount * (metropolitanRegionTripConstants.find((constant) => constant.item === 'meal_voucher_discount_quantity')?.uf_amount ?? 0)).toFixed(3)} UF</div>
                                </div>
                            </div>
                        )}




                    {/* Nacional - Otras regiones */}

                    { (travelAllowanceRequest.viatic_type === 'Nacional - Otras regiones') && (
                            <div className="w-full content-center">
                                <div className="grid md:grid-cols-5 gap-4">

                                    <div>
                                        <label htmlFor="destination_region_id" className="block text-gray-700 text-sm font-semibold mr-5">
                                            Región de destino <span className="italic">(requerido)</span>
                                        </label>
                                    </div>
                                    <div>
                                        <p className="text-sm text-center shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                                            {nationalFactors.find(region => region.id === travelAllowanceRequestDetails.destination_region_id)?.region_name ?? 'Información no disponible'}
                                        </p>
                                    </div>
                                    <div> </div>
                                    <div> </div>
                                    <div> </div>

                                    <div>
                                        <label htmlFor="season_id" className="block text-gray-700 text-sm font-semibold mr-5">
                                            Temporada
                                        </label>
                                    </div>
                                    <div>
                                        <p className="text-sm text-center shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                                            {getSeasonName()}
                                        </p>
                                    </div>
                                    <div> </div>
                                    <div> </div>
                                    <div> </div>

                                    <div> </div>
                                    <div> </div>
                                    <div> </div>
                                    <div> 
                                        <label htmlFor="adjustmentFactor" className="block text-gray-700 text-sm font-semibold mr-5 text-center">
                                            Factor de ajuste por región y temporada: {getNationalFactorValue()}
                                        </label>    
                                    </div>
                                    <div> 
                                        <label htmlFor="total_value" className="align-text-bottom block text-gray-700 text-sm font-semibold mr-5 text-center">
                                            Total
                                        </label>
                                    </div>

                                    <div>
                                        <label htmlFor="breakfast_days_only" className="block text-gray-700 text-sm font-semibold mr-5">
                                            Días de solo desayuno
                                        </label>
                                    </div>
                                    <div>
                                        <p className="text-sm text-center shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                                            {travelAllowanceRequestDetails.breakfast_days_only}
                                        </p>
                                    </div>
                                    <div className="text-center text-gray-700 font-semibold">x {nationalTripConstants.find((constant) => constant.item === 'breakfast_days_only')?.uf_amount || 0} UF</div>
                                    {getNationalFactor()}
                                    <div className="text-center text-gray-700 font-semibold">= {(getNationalFactorValue() * travelAllowanceRequestDetails.breakfast_days_only * (nationalTripConstants.find((constant) => constant.item === 'breakfast_days_only')?.uf_amount ?? 0)).toFixed(3)} UF</div>

                                    <div>
                                            <label htmlFor="lunch_days_only" className="block text-gray-700 text-sm font-semibold mr-5">
                                                Días de solo almuerzo
                                            </label>
                                    </div>
                                    <div>
                                        <p className="text-sm text-center shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                                            {travelAllowanceRequestDetails.lunch_days_only}
                                        </p>
                                    </div>
                                    <div className="text-center text-gray-700 font-semibold">x {nationalTripConstants.find((constant) => constant.item === 'lunch_days_only')?.uf_amount || 0} UF</div>
                                    {getNationalFactor()}
                                    <div className="text-center text-gray-700 font-semibold">= {(getNationalFactorValue() * travelAllowanceRequestDetails.lunch_days_only * (nationalTripConstants.find((constant) => constant.item === 'lunch_days_only')?.uf_amount ?? 0)).toFixed(3)} UF</div>
                                    
                                    <div>
                                        <label htmlFor="dinner_days_only" className="block text-gray-700 text-sm font-semibold mr-5">
                                            Días de solo cena
                                        </label>
                                    </div>
                                    <div>
                                        <p className="text-sm text-center shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                                            {travelAllowanceRequestDetails.dinner_days_only}
                                        </p>
                                    </div>
                                    <div className="text-center text-gray-700 font-semibold">x {nationalTripConstants.find((constant) => constant.item === 'dinner_days_only')?.uf_amount || 0} UF</div>
                                    {getNationalFactor()}
                                    <div className="text-center text-gray-700 font-semibold">= {(getNationalFactorValue() * travelAllowanceRequestDetails.dinner_days_only * (nationalTripConstants.find((constant) => constant.item === 'dinner_days_only')?.uf_amount ?? 0)).toFixed(3)} UF</div>

                                    <div>
                                        <label htmlFor="full_meal_days" className="block text-gray-700 text-sm font-semibold mr-5">
                                            Días de alimentación completa
                                        </label>
                                    </div>
                                    <div>
                                        <p className="text-sm text-center shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                                            {travelAllowanceRequestDetails.full_meal_days}
                                        </p>
                                    </div>
                                    <div className="text-center text-gray-700 font-semibold">x {nationalTripConstants.find((constant) => constant.item === 'full_meal_days')?.uf_amount || 0} UF</div>
                                    {getNationalFactor()}
                                    <div className="text-center text-gray-700 font-semibold">= {(getNationalFactorValue() * travelAllowanceRequestDetails.full_meal_days * (nationalTripConstants.find((constant) => constant.item === 'full_meal_days')?.uf_amount ?? 0)).toFixed(3)} UF</div>

                                    <div>
                                        <label htmlFor="housing_days" className="block text-gray-700 text-sm font-semibold mr-5">
                                            Días de alojamiento
                                        </label>
                                    </div>
                                        <p className="text-sm text-center shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                                            {travelAllowanceRequestDetails.housing_days}
                                        </p>
                                    <div className="text-center text-gray-700 font-semibold">x {nationalTripConstants.find((constant) => constant.item === 'housing_days')?.uf_amount || 0} UF</div>
                                    {getNationalFactor()}
                                    <div className="text-center text-gray-700 font-semibold">= {(getNationalFactorValue() * travelAllowanceRequestDetails.housing_days * (nationalTripConstants.find((constant) => constant.item === 'housing_days')?.uf_amount ?? 0)).toFixed(3)} UF</div>

                                    <div>
                                        <label htmlFor="housing_days" className="block text-gray-700 text-sm font-semibold mr-5">
                                            Días de bono compensatorio
                                        </label>
                                    </div>
                                    <div>
                                        <p className="text-sm text-center shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                                            {travelAllowanceRequestDetails.compensatory_bonus_quantity}
                                        </p>
                                    </div>
                                    <div className="text-center text-gray-700 font-semibold">x {nationalTripConstants.find((constant) => constant.item === 'compensatory_bonus')?.uf_amount || 0} UF</div>
                                    <div> </div>
                                    <div className="text-center text-gray-700 font-semibold">= {(travelAllowanceRequestDetails.compensatory_bonus_quantity * (nationalTripConstants.find((constant) => constant.item === 'compensatory_bonus')?.uf_amount ?? 0)).toFixed(3)} UF</div>

                                    <div>
                                        <label htmlFor="meal_vouchers_discount" className="block text-gray-700 text-sm font-semibold mr-5">
                                            Descuentos por vale de colación
                                        </label>
                                    </div>
                                    <div>
                                        <p className="text-sm text-center shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                                            {travelAllowanceRequestDetails.meal_vouchers_discount}
                                        </p>
                                    </div>
                                    <div className="text-center text-gray-700 font-semibold">x {nationalTripConstants.find((constant) => constant.item === 'meal_voucher_discount_quantity')?.uf_amount || 0} UF</div>
                                    <div></div>
                                    <div className="text-center text-gray-700 font-semibold">= - {(travelAllowanceRequestDetails.meal_vouchers_discount * (nationalTripConstants.find((constant) => constant.item === 'meal_voucher_discount_quantity')?.uf_amount ?? 0)).toFixed(3)} UF</div>

                                </div>
                            </div>
                        )}


                        {/* Internacional */}

                        { (travelAllowanceRequest.viatic_type === 'Internacional') && (
                            <div className="w-full content-center">
                                <div className="grid md:grid-cols-5 gap-4">
                                <div> </div>
                                    <div> </div>
                                    <div> </div>
                                    <div> 
                                        <label htmlFor="adjustmentFactor" className="block text-gray-700 text-sm font-semibold mr-5 text-center">
                                            Factor de ajuste por país de destino: {internationalFactors.find(country => country.id === Number(travelAllowanceRequestDetails.destination_country_id))?.factor}
                                        </label>    
                                    </div>
                                    <div> 
                                        <label htmlFor="total_value" className="align-text-bottom block text-gray-700 text-sm font-semibold mr-5 text-center">
                                            Total
                                        </label>
                                    </div>

                                    <div>
                                        <label htmlFor="breakfast_days_only" className="block text-gray-700 text-sm font-semibold mr-5">
                                            Responsabilidad del funcionario
                                        </label>
                                    </div>
                                    <div>
                                        <p className="text-sm text-center shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                                            {travelAllowanceRequestDetails.employee_responsibility_type}
                                        </p>
                                    </div>
                                    <div></div>
                                    <div></div>
                                    <div></div>

                                    <div>
                                        <label htmlFor="destination_country_id" className="block text-gray-700 text-sm font-semibold mr-5">
                                            País de destino
                                        </label>
                                    </div>
                                    <div>
                                        <p className="text-sm text-center shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                                            {internationalFactors.find(country => country.id === travelAllowanceRequestDetails.destination_country_id)?.country_name ?? 'Información no disponible'}
                                        </p>
                                    </div>
                                    <div></div>
                                    <div> </div>
                                    <div> </div>

                                    <div>
                                        <label htmlFor="destination_city" className="block text-gray-700 text-sm font-semibold mr-5">
                                            Ciudad de destino <span className="italic">(requerido)</span>
                                        </label>
                                    </div>
                                    <div>
                                        <p className="text-sm text-center shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                                            {travelAllowanceRequestDetails.destination_city ? travelAllowanceRequestDetails.destination_city : 'Información no entregada.'}
                                        </p>
                                    </div>
                                    <div> </div>
                                    <div> </div>
                                    <div> </div>
                                                                          
                                    <div>
                                        <label htmlFor="full_day_basic_viatic" className="block text-gray-700 text-sm font-semibold mr-5">
                                            Días de viático - Día completo 
                                        </label>
                                    </div>
                                    <div>
                                        <p className="text-sm text-center shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                                            {travelAllowanceRequestDetails.full_day_basic_viatic}
                                        </p>
                                    </div>
                                    <div className="text-center text-gray-700 font-semibold">x {(internationalAllowanceByRoles?.find(role => role.role === travelAllowanceRequestDetails.employee_responsibility_type)?.usd ?? 0)} USD </div>
                                    <div className="text-center text-gray-700 font-semibold">x {internationalFactors.find(country => country.id === Number(travelAllowanceRequestDetails.destination_country_id))?.factor} </div>
                                    <div className="text-center text-gray-700 font-semibold">= {(travelAllowanceRequestDetails.full_day_basic_viatic * (internationalAllowanceByRoles?.find(role => role.role === travelAllowanceRequestDetails.employee_responsibility_type)?.usd ?? 0) * (internationalFactors?.find(country => country.id === Number(travelAllowanceRequestDetails.destination_country_id))?.factor ?? 0)).toFixed(2)} USD</div>

                                    <div>
                                        <label htmlFor="half_day_basic_viatic" className="block text-gray-700 text-sm font-semibold mr-5">
                                            Días de viático - Día de trayecto (50%)
                                        </label>
                                    </div>
                                    <div>
                                        <p className="text-sm text-center shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                                            {travelAllowanceRequestDetails.half_day_basic_viatic}
                                        </p>
                                    </div>
                                    <div className="text-center text-gray-700 font-semibold">x {0.5 * (internationalAllowanceByRoles?.find(role => role.role === travelAllowanceRequestDetails.employee_responsibility_type)?.usd ?? 0)} USD </div>
                                    <div className="text-center text-gray-700 font-semibold">x {internationalFactors.find(country => country.id === Number(travelAllowanceRequestDetails.destination_country_id))?.factor} </div>
                                    <div className="text-center text-gray-700 font-semibold">= {(travelAllowanceRequestDetails.half_day_basic_viatic * 0.5 * (internationalAllowanceByRoles?.find(role => role.role === travelAllowanceRequestDetails.employee_responsibility_type)?.usd ?? 0) * (internationalFactors?.find(country => country.id === Number(travelAllowanceRequestDetails.destination_country_id))?.factor ?? 0)).toFixed(2)} USD</div>
                                </div>
                            </div>
                        )}
                </div>

                <div className="mb-4 bg-beige border border-black rounded-lg py-5">
                    <div className="grid sm:grid-cols-1 md:grid-cols-3 gap-4">
                        <div className="text-center">
                            <label htmlFor="total" className="text-gray-700 text-sm font-bold">
                                Total del viático
                            </label>
                            <div className="text-center">
                            {`${travelAllowanceRequest.requested_amount.toFixed(3)} ${travelAllowanceRequest.currency_type }`}
                            </div>
                        </div>

                        <div className="text-center">
                            <label htmlFor="total" className="block text-gray-700 text-sm font-bold mb-2">
                                Valor de {travelAllowanceRequest.currency_type} el día de la solicitud
                            </label>
                            <div className="text-center">
                                {checkIfUserCanEditCurrencyRate(permissions, travelAllowanceRequest) ? (
                                    <div>
                                        <p>Valor se debe agregar manualmente.</p>
                                        <button 
                                            className="bg-blue-400 px-2 py-1 text-md text-white rounded-sm mt-2"
                                            onClick={() => openChangeCurrencyRateModal()}> 
                                            Modificar valor de la divisa del día de la solicitud.
                                        </button>
                                    </div>
                                ) : (
                                    <p>{travelAllowanceRequest.daily_rate} CLP</p>
                                )} 
                            </div>
                        </div>

                        <div className="text-center">
                            <label htmlFor="total" className="block text-gray-700 text-sm font-bold mb-2">
                                Monto total
                            </label>
                            <div className="text-center">
                                {travelAllowanceRequest.clp_value} CLP
                            </div>
                        </div>
                    </div>
                </div>

                { (travelAllowanceRequest.vehicle !== null) && (
                    <VehicleTracker muestra={travelAllowanceRequest.vehicle.vehicle_plate} message={"Trayectoria del vehículo solicitado"}/>
                )}

                {isModalOpen && (
                    <div
                        ref={modalBackdropRef}
                        className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center"
                        onClick={handleBackdropClick}
                    >
                    <div className="bg-white rounded-lg overflow-hidden mx-4 my-4 flex flex-col">
                        <div className="w-full h-full overflow-auto p-4">
                        <div>
                            <button onClick={() => closeModal()} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center mb-5">
                                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            </button>
                            {/* <button
                                className="mb-4 p-2 text-black bg-transparent hover:bg-gray-200 rounded-sm text-sm"
                                onClick={() => closeModal()}
                            >    
                                Cerrar
                            </button> */}
                            <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_unit_id">
                                Patente del vehículo <span className="italic">(requerido)</span>
                            </label>
                            <select
                                className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="requesting_unit_id"
                                name="requesting_unit_id"
                                value={proposedVehicleId}
                                onChange={(e) => setProposedVehicleId(Number(e.target.value))}
                            >
                                <option value="">No requiere un vehículo.</option>
                                {availableVehicles.map((vehicle: any) => (
                                    <option key={vehicle.id} value={vehicle.id}>
                                        {vehicle.vehicle_plate} - {vehicle.vehicle_type} {vehicle.brand}
                                    </option>
                                ))}
                            </select>

                            {proposedVehicleId === 0 ? <p className="text-red-600 text-sm"> Selecciona un vehículo </p> : (null)}
                            <div className="grid grid-cols-1 gap-4">
                                <button
                                    className="w-full text-sm mt-4 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700 transition duration-200"
                                    onClick={() => handleEditVehicle(proposedVehicleId)}
                                >
                                    Modificar vehículo
                                </button>
                            </div>
                        </div>
                        </div>
                        </div>
                        </div>
                )}

                {isChangeCurrencyRateModalOpen && (
                    <div
                        ref={modalBackdropRef}
                        className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center"
                        onClick={handleBackdropClick}
                    >
                    <div className="bg-white rounded-lg overflow-hidden mx-5 my-5 flex flex-col">
                        <div className="w-full h-full overflow-auto p-4">
                        <div>
                        <button
                            className="mb-4 p-2 text-black bg-transparent hover:bg-gray-200 rounded-sm text-sm"
                            onClick={() => setChangeCurrencyRateModalOpen(false)}
                        >    
                            Cerrar
                        </button>
                            <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_unit_id">
                                Escribe el valor de la UF o USD (según corresponda) en CLP del día de la solicitud.
                            </label>
                            <ChangeCurrencyRateInput value={inputRateValue} onChange={handleInputChange}/>

                            <div className="grid grid-cols-1 gap-4">
                                <button
                                    className="w-full text-sm mt-4 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700 transition duration-200"
                                    onClick={() => sendChangeOfCurrencyRateRequest(inputRateValue)}
                                >
                                    Enviar el valor
                                </button>
                            </div>
                        </div>
                        </div>
                        </div>
                        </div>
                )}
                
                {isWarningModalOpen && (
                    <div className="flex items-center justify-center fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
                        <div className="relative p-5 border w-96 shadow-lg rounded-md bg-white">
                            <div className="mt-3 text-center">
                                <div className="mx-auto flex items-center justify-between">
                                <h3 className="text-lg leading-6 font-medium text-gray-900 text-center">Solicitudes para fechas simultáneas</h3>
                                <button onClick={() => closeWarningModal()} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center">
                                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </button>
                                </div>
                                <div className="mt-2 px-7 py-3">
                                <p className="text-sm text-gray-500">Selecciona una solicitud para revisar el caso.</p>
                                </div>
                                {warningMessage?.id && (
                                    warningMessage?.id.map((id: any) => (
                                        <div className="items-center px-4 py-3" key={id}>
                                            <button onClick={() => {
                                                    navigate(`/particularTravelAllowanceRequest/${id}`);
                                                    closeWarningModal();
                                                }} className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500">
                                                Solicitud número {id}
                                            </button>
                                        </div>
                                    ))
                                )}
                            </div>
                            </div>
                        </div>
                    )}
                <div className="grid sm:grid-cols-1 md:grid-cols-3 gap-4">

                    {(permissions?.isItMyRequest) && (
                        <div>
                            <button className="w-10/12 bg-gray-600 hover:bg-gray-800 px-4 py-2 rounded-md text-white text-center font-semibold" onClick={openFilesBlock}>Archivos de respaldo</button>
                        </div>
                    )}

                    {(permissions?.hasEditPermission) && (
                        <div>
                            <button
                                className={`w-10/12 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline bg-blue-600 hover:bg-blue-800 text-white`}
                                onClick={() => handleEdit(travelAllowanceRequest, travelAllowanceRequestDetails)}
                            >
                                Modificar solicitud
                            </button>
                        </div>
                    )}
                    {(permissions?.hasDeletePermission) && (
                        <div>
                            <button
                                className={`w-10/12 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline bg-red-600 hover:bg-red-800 text-white`}
                                onClick={() => handleDelete(travelAllowanceRequest.id)}
                            >
                                Eliminar solicitud
                            </button>
                        </div>
                    )}

                </div>

                {(isFilesBlockOpen) && (
                    <div className="w-full">
                        <FilesModal isOpen={isFilesBlockOpen} onClose={closeFilesBlock} openFileUploadModalInParent={openFileUploadModal} uploadedFiles={travelAllowanceRequest.justificationFiles} onEditFile={handleEditFile}/>
                    </div>
                )}

                {isFileUploadModalOpen && (
                    <UploadTravelAllowanceRequestJustificationFileModal onClose={closeFileUploadModal} actual_request_id={travelAllowanceRequest.id} file_data={currentFileToEdit}/>
                )}


                <div className="grid sm:grid-cols-1 md:grid-cols-3 gap-4">
                {(permissions?.hasResendPermission) && (
                    <div>
                        <button
                            className="w-10/12 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline bg-blue-400 hover:bg-blue-600 text-white"
                            onClick={() => openReviewModal('resendAsFormCompleter', travelAllowanceRequest.id)}
                        >
                            Reenviar solicitud
                        </button>
                    </div>
                )}

                {(!['Rechazada'].includes(travelAllowanceRequest.high_order_status) && (permissions?.hasEditCarAssignedPermission || accessPermissions['vehicleControllerAccess'])) && (
                    <div className="text-center mt-5 mb-5">
                        <button
                            className={`w-full font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline bg-gray-600 hover:bg-gray-800 text-white center`}
                            onClick={() => setIsModalOpen(true)}
                        >
                            Modificar el vehículo solicitado
                        </button>
                    </div>
                )}
                </div>

                {isReviewModalOpen && (
                    <GenericReviewModal
                        isOpen={isReviewModalOpen}
                        onClose={() => setReviewModalOpen(false)}
                        title={reviewModalProps.title}
                        message={reviewModalProps.message}
                        actionName={reviewModalProps.actionName}
                        endpoint={reviewModalProps.endpoint}
                        isCommentMandatory={reviewModalProps.isCommentMandatory}
                        onSend={reviewModalProps.onSend}
                    />
                )}
                {(permissions?.hasMarkAsReceivedPermission || (travelAllowanceRequest.high_order_status === 'Enviada' && accessPermissions['adminPermissions'])) && (
                    <div className="grid sm:grid-cols-1 md:grid-cols-3 gap-4">  
                            <button
                                className="w-full font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline bg-blue-400 hover:bg-blue-600 text-white"
                                onClick={() => openReviewModal('markAsReceived', travelAllowanceRequest.id)}
                            >
                                Marcar como recibida
                            </button>
                    </div>
                )}
                {(permissions?.chainAgentTurn.hasReviewPermissions || (['Recibida', 'En proceso de revisión'].includes(travelAllowanceRequest.high_order_status) && accessPermissions['adminPermissions'])) && (
                    <div className="grid sm:grid-cols-1 md:grid-cols-3 gap-4">
                        <div>
                            <button
                                className="w-full font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline bg-blue-400 hover:bg-blue-600 text-white"
                                onClick={() => openReviewModal('rejectRequest', travelAllowanceRequest.id)}
                            >
                                Rechazar solicitud
                            </button>
                        </div>
                        <div>
                            <button
                                className="w-full font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline bg-blue-400 hover:bg-blue-600 text-white"
                                onClick={() => openReviewModal('sendBackRequest', travelAllowanceRequest.id)}
                            >
                                Enviar de vuelta
                            </button>
                        </div>
                        <div>
                            <button
                                className="w-full font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline bg-blue-400 hover:bg-blue-600 text-white"
                                onClick={() => openReviewModal('approveRequest', travelAllowanceRequest.id)}
                            >
                                Aprobar solicitud
                            </button>
                        </div>
                    </div>
                )}
                {(permissions?.hasAccountingPermissions || (travelAllowanceRequest.high_order_status === 'Pendiente de validación por Contabilidad' && accessPermissions['adminPermissions'])) && (
                    <div className="grid sm:grid-cols-1 md:grid-cols-3 gap-4">
                        <div>
                            <button
                                className="w-full font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline bg-blue-400 hover:bg-blue-600 text-white"
                                onClick={() => openReviewModal('rejectRequestFromAccounting', travelAllowanceRequest.id)}
                            >
                                Rechazar solicitud
                            </button>
                        </div>
                        <div>
                            <button
                                className="w-full font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline bg-blue-400 hover:bg-blue-600 text-white"
                                onClick={() => openReviewModal('sendBackRequestFromAccounting', travelAllowanceRequest.id)}
                            >
                                Enviar de vuelta
                            </button>
                        </div>
                        <div>
                            <button
                                className="w-full font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline bg-blue-400 hover:bg-blue-600 text-white"
                                onClick={() => openReviewModal('markRequestAsToBePaid', travelAllowanceRequest.id)}
                            >
                                Validar cálculo del viático
                            </button>
                        </div>
                    </div>
                )}
                {(permissions?.hasTreasuryPermissions || (travelAllowanceRequest.high_order_status === 'Validada por Contabilidad' && accessPermissions['adminPermissions'])) && (
                    <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <button
                                className="w-10/12 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline bg-blue-400 hover:bg-blue-600 text-white"
                                onClick={() => openReviewModal('rejectRequestFromTreasury', travelAllowanceRequest.id)}
                            >
                                Rechazar solicitud
                            </button>
                        </div>
                        <div>
                            <button
                                className="w-10/12 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline bg-blue-400 hover:bg-blue-600 text-white"
                                onClick={() => openReviewModal('markRequestAsPaid', travelAllowanceRequest.id)}
                            >
                                Marcar como solicitud pagada
                            </button>
                        </div>
                    </div>
                )}
                <div className="mb-4">
                    <label htmlFor="total" className="block text-gray-700 text-lg font-bold mb-2 mt-6 text-center">
                        Historial de la solicitud
                    </label>
                    <div className='overflow-x-auto'>
                    <div className="min-w-screen">
                    <table className="min-w-full divide-y divide-gray-200 shadow-sm">
                        <thead className="bg-gray-50">
                        <tr>
                            <th className="px-6 py-3 text-center text-md font-medium text-gray-500">
                            Acción realizada
                            </th>
                            <th className="px-6 py-3 text-center text-md font-medium text-gray-500">
                            Agente
                            </th>
                            <th className="px-6 py-3 text-center text-md font-medium text-gray-500">
                            Comentario
                            </th>
                            <th className="px-6 py-3 text-center text-md font-medium text-gray-500">
                            Fecha
                            </th>
                        </tr>
                        </thead>
                            { (travelAllowanceRequest.approvalHistory.length > 0) ? (
                                <tbody className="bg-white divide-y divide-gray-200">
                                {travelAllowanceRequest.approvalHistory.map((action: any, index: number) => (
                                    <tr key={action.id}>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{ActionDictionary[action.action_taken]}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{action.user_name} {action.ap_pat} {action.ap_mat}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">{action.comment}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{new Date(action.createdAt).toLocaleDateString()} {new Date(action.createdAt).toLocaleTimeString()}</td>
                                    </tr>
                                ))}
                                </tbody>
                            ) : (
                                <caption className="font-custom text-sm text-grey-800 text-center">
                                    La solicitud aún no tiene historial de aprobaciones.
                                </caption>
                            )}

                        </table>
                    </div>
                    </div>
                </div>
                <div className="mt-8 text-center">
                    <h3 className="text-lg font-semibold text-left">Próximos Aprobadores:</h3>
                    <List>
                    {travelAllowanceRequest.nextApprovers.map((approver: any, index: number) => (
                        <ListItem key={index}>
                        <ListItemText
                            primary={`${index + 1} - ${approver.user_name}`}
                            secondary={`RUT: ${approver.rut}`}
                        />
                        </ListItem>
                    ))}
                    </List>
                </div>
                </div>
            </div>
        </div>
  )
}

const checkIfUserCanEditCurrencyRate = (permissions: any, travelAllowanceRequest: TravelAllowanceRequestToShow) => {
    return ((travelAllowanceRequest.daily_rate === 0) && (permissions?.hasMarkAsReceivedPermission || permissions?.chainAgentTurn.hasReviewPermissions || permissions?.hasAccountingPermissions || permissions?.hasTreasuryPermissions || permissions?.hasEditCarAssignedPermission))
}

const ActionDictionary : any = {
    "Aprobar": "Aprobó la solicitud",
    "Rechazar": "Rechazó la solicitud",
    "Devolver": "Devolvió la solicitud",
    "Recibir": "Marcó la solicitud como recibida",
    "Reenviar": "Reenvió la solicitud",
    "Validada por Contabilidad": "Envió la solicitud a Tesorería",
    "Pagada por Tesorería": "Tesorería ya pagó la solicitud"
}