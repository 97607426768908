import React, { useState, useEffect } from 'react'
import { Formik, Form, FieldArray, Field, FormikProvider } from 'formik';
import renderFormikError from '../../elements/renderFormikError';
import * as Yup from 'yup';
import { useAuthStatus } from '../../hooks/useAuthStatus';
import BlockScreen from '../blockScreen/blockScreen';
import { useToast } from '../../context/useToast';
import 'dayjs/locale/es';
import Loader from '../../elements/loader';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom';
import _ from 'lodash';
import performApiRequest from '../../utils/performApiRequest';
import { useDataForEndpoint } from '../../context/dataProvider';
import DeleteIcon from '@mui/icons-material/Delete';
import { esES } from '@mui/x-date-pickers/locales';
import 'dayjs/locale/es';
//import { format } from 'date-fns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'react-calendar/dist/Calendar.css';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ThemeProvider } from '@mui/material/styles';
import dayjs from 'dayjs';
import theme from "../../utils/calendarTheme"
import formatRUT from '../../utils/formatRUT';
import { API_ENDPOINTS } from '../../utils/endpoints';
import { OrganizationalUnit,
    BenefitCenter,
    Project } from '../../types/fields';
import FilePreview from '../../elements/filePreview';
import { obtainExchangeRate } from '../../utils/obtainExchangeRate';
import { useAccessPermissions } from '../../hooks/useAccessPermission';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Tooltip } from 'react-tooltip'

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Santiago');



const inFavorOfSolicitantOptions = [
    {id: false, label: 'No'},
    {id: true, label: 'Sí'}
]

const particularExpenseSchema = Yup.object().shape({
    id: Yup.number(),
    note: Yup.string()
        .required('Debes ingresar una descripción del gasto.')
        .max(40, 'La información adicional no puede tener más de 40 caracteres.'),
    document_type: Yup.string().oneOf(['Factura', 'Boleta electrónica', 'Boleta de Transbank', 'Comprobante de egreso', 'Invoice', 'Otro'], 'Debe ser una de las opciones disponibles.')
        .when('amount', (amount, schema) => {
            return Number(amount) > 50000
          ? schema.oneOf(['Factura'], 'Para montos mayores a 50000, el tipo de documento debe ser Factura.')
          : schema;
        }).required('Debes seleccionar una de las opciones.'),
    document_number: Yup.string().required('Debes ingresar el no. de documento.'),
    business_name: Yup.string().required('Ingresa el nombre de la empresa.'),
    business_rut: Yup.string().required('Ingresa el RUT del proveedor o equivalente.'),
    category_id: Yup.number().required('Debes seleccionar una de las categorías disponibles.'),
    benefit_center_prc_code: Yup.string().required('Debes seleccionar el centro de beneficio.'),
    project_number: Yup.string().required('Debes indicar si tiene o no número de proyecto.'),
    amount: Yup.number().required('Ingresa el monto indicado en el documento.').min(1, 'El monto no puede ser 0 o menor.'),
    currency_id: Yup.number().required('Debes seleccionar una de las divisas.'),
    files: Yup.array().of(Yup.mixed()),
    expense_date: Yup.string().required('Debes ingresar la fecha del gasto.'),
    exchange_rate: Yup.number(),
    clp_amount: Yup.number(),
});

const baseSchema = Yup.object().shape({
    is_it_for_another_employee: Yup.bool().required('Debes seleccionar una opción.'),
    request_title: Yup.string().required('Asígnale un nombre a la solicitud'),
    requesting_employee_rut: Yup.string().when('is_it_for_another_employee', (is_it_for_another_employee: any, schema: Yup.StringSchema) =>
        // eslint-disable-next-line eqeqeq
        is_it_for_another_employee == "true" ? schema.required('Ingresa el RUT del usuario.').min(8, 'Ingresa el dígito verificador.') : schema.notRequired()
    ),
    expense_request_type: Yup.string().oneOf(['Rendición de fondos por rendir', 'Solicitud de reembolso', 'Rendición de fondos rotatorios'], 'Debe ser una de las opciones disponibles.').required('Debes seleccionar una opción.'),
    requesting_unit_id: Yup.string().required('Debes seleccionar una unidad organizacional.'),
    assigned_funds: Yup.number().min(0, 'Los fondos asignados no pueden ser negativos.'),
    currency_id: Yup.number(),
    in_favor_of_solicitant: Yup.boolean().required('Debes marcar una opción.'),
    comment: Yup.string().max(100, 'No puede exceder los 100 caracteres.'),
    beneficiary_name: Yup.string().max(40, 'No puede exceder los 40 caracteres.'),
    beneficiary_current_account_number: Yup.string().max(40, 'No puede exceder los 40 caracteres.'),
    beneficiary_rut: Yup.string().max(15, 'No puede exceder los 15 caracteres.'),
    beneficiary_bank: Yup.string().max(30, 'No puede exceder los 30 caracteres.'),
    status: Yup.string().oneOf(['Borrador', 'Enviado'], 'Debes seleccionar una de las opciones'),
    particular_expenses: Yup.array().of(particularExpenseSchema)
});

type ModifyExpenseReportRequestData = {
    requestData?: {[key: string]: any};
}

const ExpenseReportRequestForm: React.FC<ModifyExpenseReportRequestData> = () => {
    const isAuthenticated = useAuthStatus();
    const [expenseCategories, setExpenseCategories] = useState([]);
    const benefitCenters = useDataForEndpoint<BenefitCenter[]>('benefit_centers');
    const organizationalUnits = useDataForEndpoint<OrganizationalUnit[]>('organizational_units');
    const projects = useDataForEndpoint<Project[]>('projects');
    const [availableUnits, setAvailableUnits] = useState<OrganizationalUnit[]>([]);
    const currencies = useDataForEndpoint<any[]>('currencies');
    const [isLoading, setIsLoading] = useState(true);
    //const [hasNoProjectNumber, setHasNoProjectNumber] = useState(false);
    const navigate = useNavigate();
    const { showSuccessToast, showErrorToast } = useToast();
    const [isTheFunctionInterrupted, setIsTheFunctionInterrupted] = useState(false);
    const location = useLocation();
    const { requestData } = location.state || {};
    const [openModalId, setOpenModalId] = useState(null);
    const [localFiles, setLocalFiles] = useState<any[]>([]);
    const [activatedAddFileToExpense, setActivatedAddFileToExpense] = useState<number[]>([]);
    const { accessPermissions } = useAccessPermissions();
    const [nextId, setNextId] = useState(200);
    const handleOpen = (id: any) => {
        setOpenModalId(id);
    };

    console.log('requestData: ',requestData);

    const handleClose = () => {
        setOpenModalId(null);
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
          const requestOptions = { method: 'GET' };
          const response = await performApiRequest(API_ENDPOINTS.getExpenseCategories, requestOptions);
          if (response.status === 200) {
            setExpenseCategories(response.data);
          }
        } catch (error) {
          console.error('Error fetching bill accounts:', error);
        }
    };

    useEffect(() => {
        const allData = [
          organizationalUnits,
          benefitCenters,
          currencies,
          projects
        ];
        
        if (isAuthenticated) {
            const isAnyDataUndefined = allData.some(data => data === undefined);
            setIsLoading(isAnyDataUndefined);
        }
    }, [organizationalUnits, benefitCenters, currencies, projects, isAuthenticated]);

    const retrieveUnitsUserCanMakeRequestsTo = async () => {
        if (isAuthenticated && availableUnits.length === 0) {
            try {
                const requestOptions = { method: 'GET' };
                const response = await performApiRequest(API_ENDPOINTS.getUnitsUserIsSubmitterOf, requestOptions);
                console.log('response: ',response);
                if (response.status === 200) {
                    setAvailableUnits(response.data.units || []);
                } else {
                    setAvailableUnits([]);
                }
                console.log('availableUnits: ', availableUnits);
            } catch (error) {
                console.error('Error fetching units:', error);
                showErrorToast('Ocurrió un error. Inténtalo de nuevo.');
                setAvailableUnits([]);
            }
        }
    }

    useEffect(() => {
        retrieveUnitsUserCanMakeRequestsTo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated])

    if (!isAuthenticated) {
        return (
            <BlockScreen/>
        )
    }


    const handleRUTChange = (rut: string) => {
        return formatRUT(rut);
    };

    const addLocalFile = (event: React.ChangeEvent<HTMLInputElement>, expenseId: number, index: number) => {
        const file = event.target.files ? event.target.files[0] : null;
        if (file && expenseId !== undefined && index !== undefined) {
            const newFileList = [...localFiles, { file: file, expenseId: expenseId, index: index }];
            setLocalFiles(newFileList);
            console.log("Added file:", file);
            console.log("New localFiles state:", newFileList);
        } else {
            console.error("Failed to add file: ", {file, expenseId, index});
        }
    };

    const removeLocalFile = (index: number) => {
        setLocalFiles(localFiles.filter((file: any, i: number) => index !== file.index));
    };
    

    const sendExpenseReportRequestToBackend = async(values: any, setTouched: any, validateForm: any) => {
        console.log(values);
        setIsTheFunctionInterrupted(false);
        //await setIsTheFunctionInterruptedAsync(false);
        console.log('isTheFunctionInterrupted: ', isTheFunctionInterrupted);



        setIsLoading(true);
        const anyWithoutFiles = values.particular_expenses.some((expense: any) => {
            if (((expense.files.some((file: any) => file.file_url && !values.idOfFilesToBeDeleted.includes(file.id))) || (expense.files.some((file: any) => file.name && !values.idOfFilesToBeDeleted.includes(file.name)))) || localFiles.length > 0) {
                return false;
            } else {
                return true;
            }
            // return expense.files.length === 0 || 
            // expense.files.every((file: any) => file === null || values.idOfFilesToBeDeleted.includes(file.id));
        });

        if (anyWithoutFiles) {
            showErrorToast("Cada gasto debe tener al menos un archivo de respaldo.");
            console.log('anyWithoufiles');
            console.log(values.particular_expenses);
            console.log(localFiles);
            //setIsTheFunctionInterrupted(true);
            setIsLoading(false);
            return;
        }

       


        const formData = new FormData();
        for (let [index, expense] of values.particular_expenses.entries()) {
            if (expense.files.length) {
                expense.files.forEach((file: any, fileIndex: number) => {
                    formData.append(`files[${index}][${fileIndex}]`, file);
                });
            }
            if (Number(expense.amount) > 50000 && expense.document_type !== 'Factura') {
                showErrorToast("Si el valor supera los 50.000 CLP, el documento adjunto debe ser una factura.");
                //setIsTheFunctionInterrupted(true);
                setIsLoading(false);
                return;
            }


            //console.log('expense: ',expense);
            //console.log('formated: ',format(expense.expense_date, 'yyyy-MM-dd'));
            //console.log('formated2: ',dayjs(expense.expense_date).format('YYYY-MM-DD'));
            formData.append(`particular_expenses[${index}].id`, expense.id);
            formData.append(`particular_expenses[${index}].expense_date`, dayjs(expense.expense_date).format('YYYY-MM-DD'));
            formData.append(`particular_expenses[${index}].note`, expense.note);
            formData.append(`particular_expenses[${index}].document_type`, expense.document_type);
            formData.append(`particular_expenses[${index}].document_number`, expense.document_number);
            formData.append(`particular_expenses[${index}].business_name`, expense.business_name);
            formData.append(`particular_expenses[${index}].business_rut`, handleRUTChange(expense.business_rut));
            formData.append(`particular_expenses[${index}].category_id`, expense.category_id);
            formData.append(`particular_expenses[${index}].benefit_center_prc_code`, expense.benefit_center_prc_code);
            formData.append(`particular_expenses[${index}].project_number`, expense.project_number);
            formData.append(`particular_expenses[${index}].amount`, expense.amount);
            formData.append(`particular_expenses[${index}].currency_id`, expense.currency_id);
            formData.append(`particular_expenses[${index}].status`, expense.status);
            formData.append(`particular_expenses[${index}].exchange_rate`, expense.exchange_rate);
            formData.append(`particular_expenses[${index}].clp_amount`, expense.clp_amount);
        };

        Object.entries(values).forEach(([key, value]) => {
            if (key !== 'particular_expenses') {
                formData.append(key, value as string);
            }
        });
        
        localFiles.forEach((fileObject: any, index: number) => {
            formData.append(`newFiles[${index}]`, fileObject.file);
            formData.append(`newFiles[${index}].expenseId`, fileObject.expenseId);
            formData.append(`newFiles[${index}].index`, fileObject.index);
        });
        
        const updateRequestOptions = {
            method: 'PATCH',
            data: formData
        };
        
        const requestOptions = {
            method: 'POST',
            data: formData
        };

        /*for (let pair of formData.entries()) {
            console.log(`${pair[0]}: ${pair[1]}`);
        }*/

        for (let [key, value] of formData.entries()) {
            console.log(key, value);
          }
        
        console.log('isTheFunctionInterrupted: ', isTheFunctionInterrupted);

        //console.log('datos antes llamar API: ',requestData);
        
        if (!isTheFunctionInterrupted) {
            try {
                //console.log('requestData: ',requestData);
                if (requestData) {
                    for (let [key, value] of formData.entries()) {
                        console.log(`${key}: ${value}`);
                      }
                    const response = await performApiRequest(`${API_ENDPOINTS.updateExpenseReportRequest}/${requestData.id}`, updateRequestOptions)
                    //console.log('response: ',response);
                    if (response.status === 204) {
                        showSuccessToast('Solicitud actualizada correctamente');
                        navigate('/allExpenseReportRequests');
                    }
                } else {
                    const response = await performApiRequest(API_ENDPOINTS.createExpenseReportRequest, requestOptions)
                    console.log('response: ',response);
                    if (response.status === 201) {
                        showSuccessToast('Solicitud realizada correctamente');
                        navigate('/allExpenseReportRequests');
                    }
                }
            } catch (error: any) {
                //console.log('Error atrapado: ',error);
                setIsLoading(false);
                //console.log('Error atrapado: ',error);
                showErrorToast(`Error: ${error.message}`);
                return;
            }
        } else {
            window.alert("Arreglar el error antes de volver a enviar.");
            return;
        }
    }

    const handleSetTouchedAll = (setTouched: any, values: { [key: string]: any }) => {
        const touchedFields: { [key: string]: boolean }  = Object.keys(values).reduce((acc: any, key) => {
          acc[key] = true;
          return acc;
        }, {});
        setTouched(touchedFields);

        for (let [index, expense] of values.particular_expenses.entries()) {
            const touchedFields: { [key: string]: boolean }  = Object.keys(expense).reduce((acc: any, key) => ({[`particular_expenses[${index}].${key}`]: true, ...acc}), {})
            setTouched(touchedFields);
        }
        
    };

    if (isLoading) {
        return (<Loader message={"Cargando datos..."}/>)
    }


    return (
        <Formik
            initialValues = {{
                is_it_for_another_employee: requestData ? (requestData.formCompleterUserData.rut === requestData.requestingUserData.rut) : false,
                request_title: requestData?.request_title || '',
                requesting_employee_rut: requestData?.requestingUserData ? (`${requestData?.requestingUserData?.rut}-${requestData?.requestingUserData?.dv_rut}`) : '',
                expense_request_type: requestData?.expense_request_type || '',
                requesting_unit_id: requestData?.organizationalUnit?.organizational_unit_id || '',
                assigned_funds: requestData?.assigned_funds || 0,
                status: requestData?.status || '',
                in_favor_of_solicitant: requestData?.in_favor_of_solicitant || false,
                comment: requestData?.comment || '',
                beneficiary_name: requestData?.beneficiary_name || '',
                beneficiary_current_account_number: requestData?.beneficiary_current_account_number || '',
                beneficiary_rut: requestData?.beneficiary_rut || '',
                beneficiary_bank: requestData?.beneficiary_bank || '',
                particular_expenses: requestData?.particular_expenses?.map((expense: any) => ({
                    id: expense.id || '',
                    status: 'keep',
                    note: expense.note || '',
                    document_type: expense.document_type || '',
                    document_number: expense.document_number || '',
                    business_name: expense.business_name || '',
                    business_rut: expense.business_rut || '',
                    category_id: expense.category_id || 1,
                    benefit_center_prc_code: expense.benefit_center_prc_code || '',
                    project_number: expense.project_number || 'No tiene número de proyecto',
                    amount: expense.amount || 0,
                    exchange_rate: expense.exchange_rate || 0,
                    clp_amount: expense.clp_amount || 0,
                    currency_id: expense.currency_id || 1,
                    expense_date: dayjs(expense.expense_date).utc().format('YYYY-MM-DD') || dayjs().format('YYYY-MM-DD'),
                    files: expense.files || []
                })) || [
                    {
                      id: nextId,
                      status: 'new',
                      note: '',
                      document_type: '',
                      document_number: '',
                      business_name: '',
                      business_rut: '',
                      category_id: 0,
                      benefit_center_prc_code: '',
                      project_number: 'No tiene número de proyecto',
                      amount: 0,
                      exchange_rate: 0,
                      clp_amount: 0,
                      currency_id: 1,
                      expense_date: dayjs().format('YYYY-MM-DD'),
                      files: [],
                    },
                ],
                idOfFilesToBeDeleted: [] as number[],
            }}
            validationSchema={baseSchema}
            onSubmit={async (values, {setTouched,validateForm}) => {
                sendExpenseReportRequestToBackend(values, setTouched, validateForm);
            }}
        >
        {formik => (
          <FormikProvider value={formik}>
            <Form className="space-y-6">
                <h1 className="font-bold mb-5 text-lg text-gray-800"> Datos generales </h1>
                {/*<ToastContainer/>*/}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {/* <div>
                        <label className="inline-flex items-center">
                            <input
                                type="checkbox"
                                id="is_it_for_another_employee"
                                className="form-checkbox h-5 w-5 text-gray-600 mr-3 my-5"
                                value={String(formik.values.is_it_for_another_employee)}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <span className="text-gray-700 mr-4">Quiero solicitar un gasto en nombre de otra persona.</span>
                        </label>
                    </div> 
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">

                        { formik.values.is_it_for_another_employee && 
                            (
                                <div>
                                    <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_employee_rut">
                                        RUT del funcionario <span className="italic">(requerido)</span>
                                    </label>
                                    <input
                                        className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        id="requesting_employee_rut"
                                        name="requesting_employee_rut"
                                        value={formik.values.requesting_employee_rut}
                                        onChange={(e: any) => {
                                            const formattedRUT = formatRUT(e.target.value);
                                            formik.setFieldValue(`requesting_employee_rut`, formattedRUT);            
                                        }}
                                        onBlur={formik.handleBlur}
                                        disabled={!!requestData}
                                    />
                                    {formik.touched.requesting_employee_rut && formik.errors.requesting_employee_rut ? (
                                        <div className="text-red-500 text-sm">{formik.errors.requesting_employee_rut}</div>
                                    ) : null}
                                </div>
                        )}

                        formik.values.is_it_for_another_employee && 
                            (
                                <div>
                                </div>
                            )
                        
                    </div> */}
                    <div>
                        <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_employee_rut">
                            Título de la solicitud <span className="italic">(requerido)</span>
                        </label>
                        <input
                            className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="request_title"
                            name="request_title"
                            value={formik.values.request_title}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />

                        {formik.touched.request_title && formik.errors.request_title ? renderFormikError(formik.errors.request_title) : null}
                    </div>

                    <div>
                        <label htmlFor="expense_request_type" className="block text-gray-700 text-sm font-semibold mb-2">
                            Tipo de solicitud
                        </label>
                        <select
                            className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="expense_request_type"
                            onChange={(e) => {
                                formik.handleChange(e);
                                const value = e.target.value;
                                if (value === "Solicitud de reembolso") {
                                    formik.setFieldValue("in_favor_of_solicitant",true);
                                }
                            }}
                            value={formik.values.expense_request_type}
                            onBlur={formik.handleBlur}
                        >
                            <option value=""> Selecciona una de las opciones </option>
                            <option value="Rendición de fondos por rendir"> Rendición de fondos por rendir </option>
                            <option value="Solicitud de reembolso"> Solicitud de reembolso </option>
                            <option value="Rendición de fondos rotatorios"> Rendición de fondos rotatorios </option>
                        </select>

                        {formik.touched.expense_request_type && formik.errors.expense_request_type ? (
                            renderFormikError(formik.errors.expense_request_type)
                        ) : null}
                    </div>

                    {/* <div>
                        <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_employee_rut">
                            RUT del funcionario <span className="italic">(requerido)</span>
                        </label>
                        <input
                            className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="requesting_employee_rut"
                            name="requesting_employee_rut"
                            value={formik.values.requesting_employee_rut}
                            onChange={(e: any) => {
                                const formattedRUT = formatRUT(e.target.value);
                                formik.setFieldValue(`requesting_employee_rut`, formattedRUT);
                            }}
                            onBlur={formik.handleBlur}
                        />

                        {formik.touched.requesting_employee_rut && formik.errors.requesting_employee_rut ? (
                            <div className="text-red-500 text-sm">{formik.errors.requesting_employee_rut}</div>
                        ) : null}
                    </div> */}

                    <div>
                        <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_unit_id">
                            Unidad solicitante <span className="italic">(requerido)</span>
                        </label>
                        <select
                            className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="requesting_unit_id"
                            name="requesting_unit_id"
                            value={formik.values.requesting_unit_id}
                            disabled={requestData}
                            onChange={(e) => {
                                formik.handleChange(e);
                            }}
                            onBlur={formik.handleBlur}
                        >
                            <option value="">
                                Seleccione una unidad organizacional.
                            </option>
                            {Array.isArray(availableUnits) && availableUnits.map((unit) => (
                                <option key={unit.organizational_unit_id} value={unit.organizational_unit_id}>
                                    {unit.organizational_unit_name}
                                </option>
                            ))}
                        </select>

                        {formik.touched.requesting_unit_id && formik.errors.requesting_unit_id ? (
                            renderFormikError(formik.errors.requesting_unit_id)
                        ) : null}
                    </div>

                    <div>
                        <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="comment">
                            Comentario opcional
                        </label>
                        <input
                            className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="comment"
                            name="comment"
                            value={formik.values.comment}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />

                        {formik.touched.comment && formik.errors.comment ? (
                            renderFormikError(formik.errors.comment)
                        ) : null}
                    </div>
                </div>

              <h1 className="font-bold mb-5 text-lg text-gray-800"> Detalle de la rendición </h1>

              <FieldArray name="particular_expenses">
                {({ remove, push }) => (
                  <div>
                    {formik.values.particular_expenses.map((expense: any, index: number) => (
                      < div key={index}>
                            <div className="flex flex-row">
                                <h1 className="font-bold mb-5"> Gasto {index + 1} { expense.status === 'deleted' && (
                                <div>
                                    <div className="text-red-800 text-sm font-bold"> Eliminado </div>
                                        <button
                                            className="rounded-md text-sm bg-blue-600 text-white px-1 py-1" 
                                            onClick={() => formik.setFieldValue(`particular_expenses[${index}].status`, 'keep')}> 
                                            Deshacer eliminación del gasto
                                        </button>
                                    </div>
                                )} 
                                </h1>
                            </div>


                        <div className="mb-5 bg-gray-50 rounded-lg"> 
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 items-center px-5 py-5">

                            <div>
                                <label htmlFor={`particular_expenses[${index}].document_type`} className="block text-gray-700 text-sm font-semibold mb-2">
                                    Tipo de documento <span className="italic">(requerido)</span>
                                </label>
                                <select
                                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id={`particular_expenses[${index}].document_type`}
                                    name={`particular_expenses[${index}].document_type`}
                                    value={formik.values.particular_expenses[index].document_type}
                                    onChange={formik.handleChange}
                                >
                                    <option value=""> Ninguno seleccionado </option>
                                    <option value="Factura"> Factura </option>
                                    <option value="Boleta electrónica"> Boleta electrónica </option>
                                    <option value="Boleta de Transbank"> Boleta de Transbank </option>
                                    <option value="Comprobante de egreso"> Comprobante de egreso </option>
                                    <option value="Invoice"> Invoice </option>
                                    <option value="Otro"> Otro </option>
                                </select>

                                {getError(formik, `particular_expenses[${index}].document_type`)}
                            </div>

                            <div>
                                <label htmlFor={`particular_expenses[${index}].document_number`} className="block text-gray-700 text-sm font-semibold mb-2">
                                    Número de documento <span className="italic">(requerido)</span>
                                </label>
                                <Field
                                    id={`particular_expenses[${index}].document_number`}
                                    name={`particular_expenses[${index}].document_number`}
                                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                />
                                {getError(formik, `particular_expenses[${index}].document_number`)}
                            </div>

                            <div>
                                <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="vehicle_type_id">
                                    Fecha del gasto <span className="italic">(requerido)</span>
                                </label>
                                <ThemeProvider theme={theme}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es" localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}>
                                        <DatePicker
                                            label="Fecha"
                                            format="DD-MM-YYYY"
                                            disableFuture
                                            className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            value={dayjs(expense.expense_date).utc()}
                                            onChange={async(date) => {
                                                if (date) {
                                                    const formattedDate = dayjs(date).format('YYYY-MM-DD');
                                                    //console.log('formattedDate: ',formattedDate);
                                                    formik.setFieldValue(`particular_expenses[${index}].expense_date`, formattedDate);
                                                };
                                                if (formik.values.particular_expenses[index].currency_id !== 1) {                                
                                                    const selectedCurrencyId = Number(formik.values.particular_expenses[index].currency_id);
                                                    const selectedCurrencyCode = currencies.find(c => c.id === selectedCurrencyId)?.currency_code;
                                                    const expenseDate = formik.values.particular_expenses[index].expense_date;
                                                 

                                                    if (selectedCurrencyCode && expenseDate) {
                                                        const exchangeRate = await obtainExchangeRate(expenseDate, selectedCurrencyCode);
                                                        if (exchangeRate > 0) {
                                                            formik.setFieldValue(`particular_expenses[${index}].exchange_rate`, exchangeRate);
                                                            formik.setFieldValue(`particular_expenses[${index}].clp_amount`, Math.round(exchangeRate*formik.values.particular_expenses[index].amount));
                                                        } else {
                                                            formik.setFieldValue(`particular_expenses[${index}].exchange_rate`, 0);
                                                            formik.setFieldValue(`particular_expenses[${index}].clp_amount`, 0);
                                                        }
                                                    } else {
                                                        formik.setFieldValue(`particular_expenses[${index}].exchange_rate`, 0);
                                                        formik.setFieldValue(`particular_expenses[${index}].clp_amount`, 0);
                                                    }
                                                }
                                            }}
                                        />
                                    </LocalizationProvider>
                                </ThemeProvider>
                            </div>

                            <div>
                                <label htmlFor={`particular_expenses[${index}].business_name`} className="block text-gray-700 text-sm font-semibold mb-2">
                                    Razón social del proveedor <span className="italic">(requerido)</span>
                                </label>
                                <Field
                                    id={`particular_expenses[${index}].business_name`}
                                    name={`particular_expenses[${index}].business_name`}
                                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                />
                                {getError(formik, `particular_expenses[${index}].business_name`)}
                            </div>

                            <div>
                                <label htmlFor={`particular_expenses[${index}].business_rut`} className="block text-gray-700 text-sm font-semibold mb-2">
                                    RUT del proveedor <span className="italic">(requerido)</span>
                                </label>
                                <Field
                                    onChange={(e: any) => {
                                        const formattedRUT = formatRUT(e.target.value);
                                        formik.setFieldValue(`particular_expenses[${index}].business_rut`, formattedRUT);
                                    }}
                                    id={`particular_expenses[${index}].business_rut`}
                                    name={`particular_expenses[${index}].business_rut`}
                                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                />
                                {getError(formik, `particular_expenses[${index}].business_rut`)}
                            </div>

                            <div>
                                <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="benefit_center_prc_code">
                                    Centro de beneficio <span className="italic">(requerido)</span>
                                </label>
                                <select
                                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id={`particular_expenses[${index}].benefit_center_prc_code`}
                                    name={`particular_expenses[${index}].benefit_center_prc_code`}
                                    value={formik.values.particular_expenses[index].benefit_center_prc_code}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                >
                                    <option value="">Ver centros de beneficio</option>
                                    {benefitCenters.filter((center) => center.unit_id === formik.values.requesting_unit_id).map((center: any) => (
                                        <option key={center.id} value={center.prc_code}>
                                            {center.prc_code} - {center.benefit_center_name}
                                        </option>
                                    ))}
                                </select>

                                {getError(formik, `particular_expenses[${index}].benefit_center_prc_code`)}
                            </div>

                            <div>
                                <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="category_id">
                                    Categoría del gasto
                                </label>
                                <select
                                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id={`particular_expenses[${index}].category_id`}
                                    name={`particular_expenses[${index}].category_id`}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.particular_expenses[index].category_id}
                                >
                                    {/*<option value="">Ver categorías</option>*/}
                                    {expenseCategories.map((category: any) => (
                                        <option key={category.id} value={category.id}>
                                            {category.name} - {category.description}
                                        </option>
                                    ))}
                                </select>

                                {getError(formik, `particular_expenses[${index}].category_id`)}
                            </div>
                            <div>
                                <label htmlFor={`particular_expenses[${index}].project_number`} className="block text-gray-700 text-sm font-semibold mb-2">
                                    Número de proyecto
                                </label>
                                <div className="flex items-center">
                                    {/* <label className="inline-flex items-center">
                                        <input
                                            type="checkbox"
                                            className="form-checkbox h-5 w-5 text-gray-600 mr-3"
                                            checked={formik.values.particular_expenses[index].project_number === 'No tiene número de proyecto'}
                                            onChange={(e) => {
                                                setHasNoProjectNumber(e.target.checked);
                                                if (!e.target.checked) {
                                                    formik.setFieldValue(`particular_expenses[${index}].project_number`, '');
                                                } else {
                                                    formik.setFieldValue(`particular_expenses[${index}].project_number`, 'No tiene número de proyecto');
                                                }
                                            }}
                                        />
                                        <span className="text-gray-700 mr-4">No tiene.</span>
                                    </label>
                                    <Field
                                        disabled={hasNoProjectNumber}
                                        id={`particular_expenses[${index}].project_number`}
                                        name={`particular_expenses[${index}].project_number`}
                                        className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    /> */}
                                    <select
                                        className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        id={`particular_expenses[${index}].project_number`}
                                        name={`particular_expenses[${index}].project_number`}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.particular_expenses[index].project_number}
                                    >
                                        <option value="No tiene número de proyecto">No tiene número de Proyecto</option>
                                        {projects.filter((project) => project.prc_code === formik.values.particular_expenses[index].benefit_center_prc_code).map((project: any) => (
                                            <option key={project.prj_code} value={project.prj_code}>
                                                {project.prj_code} - {project.prj_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                {getError(formik, `particular_expenses[${index}].project_number`)}
                            </div>

                            <div>
                                <label htmlFor={`particular_expenses[${index}].amount`} className="block text-gray-700 text-sm font-semibold mb-2">
                                    Monto <span className="italic">(requerido)</span>
                                </label>
                                <Field
                                    min="0"
                                    type="number"
                                    id={`particular_expenses[${index}].amount`}
                                    name={`particular_expenses[${index}].amount`}
                                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    onBlur={formik.handleBlur}
                                    onChange={async (event: any) => {
                                        formik.handleChange(event);
                                        const amount = event.target.value;
                                        if (formik.values.particular_expenses[index].exchange_rate > 0) {
                                                formik.setFieldValue(`particular_expenses[${index}].clp_amount`, Math.round(formik.values.particular_expenses[index].exchange_rate*amount));
                                        } else {
                                            formik.setFieldValue(`particular_expenses[${index}].clp_amount`, 0);
                                        }
                                    }}
                                />
                                {getError(formik, `particular_expenses[${index}].amount`)}
                            </div>

                            <div>
                                <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="currency_id">
                                    Divisa <span className="italic">(requerido)</span>
                                </label>
                                <select
                                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id={`particular_expenses[${index}].currency_id`}
                                    name={`particular_expenses[${index}].currency_id`}
                                    value={formik.values.particular_expenses[index].currency_id}
                                    onChange={async (event) => {
                                        formik.handleChange(event);
                                
                                        const selectedCurrencyId = Number(event.target.value);
                                        const selectedCurrencyCode = currencies.find(c => c.id === selectedCurrencyId)?.currency_code;
                                        const expenseDate = formik.values.particular_expenses[index].expense_date;
                                
                                        if (selectedCurrencyId !== 1 && selectedCurrencyCode && expenseDate) {
                                            const exchangeRate = await obtainExchangeRate(expenseDate, selectedCurrencyCode);
                                            if (exchangeRate > 0) {
                                                formik.setFieldValue(`particular_expenses[${index}].exchange_rate`, exchangeRate);
                                                formik.setFieldValue(`particular_expenses[${index}].clp_amount`, Math.round(exchangeRate*formik.values.particular_expenses[index].amount));
                                            } else {
                                                formik.setFieldValue(`particular_expenses[${index}].exchange_rate`, 0);
                                                formik.setFieldValue(`particular_expenses[${index}].clp_amount`, 0);
                                            }
                                        } else {
                                            formik.setFieldValue(`particular_expenses[${index}].exchange_rate`, 0);
                                            formik.setFieldValue(`particular_expenses[${index}].clp_amount`, 0);
                                        }
                                    }}
                                    onBlur={formik.handleBlur}
                                >
                                    <option value="">Seleccione una divisa</option>
                                    {currencies.sort((a, b) => a.currency_code.localeCompare(b.currency_code)).map((currency) => (
                                        <option key={currency.id} value={currency.id}>
                                            {currency.currency_code}
                                        </option>
                                    ))}
                                </select>
                                {getError(formik, `particular_expenses[${index}].currency_id`)}
                            </div>

                            {(Number(formik.values.particular_expenses[index].currency_id) !== 1) && 
                                (
                                    <div>
                                        <label htmlFor={`particular_expenses[${index}].exchange_rate`} className="block text-gray-700 text-sm font-semibold mb-2">
                                            Tasa de cambio
                                        </label>
                                        <Field
                                            type="number"
                                            disabled={accessPermissions.expenseReportSystem.accountingPermissions !== true}
                                            id={`particular_expenses[${index}].exchange_rate`}
                                            name={`particular_expenses[${index}].exchange_rate`}
                                            className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            onChange={async (event: any) => {
                                                formik.handleChange(event);
                                                formik.setFieldValue(`particular_expenses[${index}].clp_amount`, Math.round(formik.values.particular_expenses[index].exchange_rate*formik.values.particular_expenses[index].amount));
                                            }}
                                        />
                                        {getError(formik, `particular_expenses[${index}].exchange_rate`)}
                                    </div>
                                )
                            }

                            {(Number(formik.values.particular_expenses[index].currency_id) !== 1) && 
                                (
                                    <div>
                                        <label htmlFor={`particular_expenses[${index}].clp_amount`} className="block text-gray-700 text-sm font-semibold mb-2">
                                            Monto (CLP) <span className="italic">(requerido)</span>
                                        </label>
                                        <Field
                                            type="number"
                                            disabled={true}
                                            id={`particular_expenses[${index}].clp_amount`}
                                            name={`particular_expenses[${index}].clp_amount`}
                                            className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        />
                                        {getError(formik, `particular_expenses[${index}].clp_amount`)}
                                    </div>
                                )
                            }

                            <div>
                                <label htmlFor={`particular_expenses[${index}].project_number`} className="block text-gray-700 text-sm font-semibold mb-2">
                                    Nota <span className="italic">(requerido)</span>
                                </label>
                                <Field
                                    id={`particular_expenses[${index}].note`}
                                    name={`particular_expenses[${index}].note`}
                                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                />
                                {getError(formik, `particular_expenses[${index}].note`)}
                            </div>
                        </div>

                        <div className="ml-4">
                        { formik.values.particular_expenses[index].files.filter((file: any) => file && file.file_url  && file.file_url.length > 0).map((file: any, secondIndex: number) => (
                                <div key={secondIndex} className="flex justify-left items-center gap-2 mb-2">
                                    <div>
                                        {file.file_name.length > 20 ? `${file.file_name.slice(0, 20)}...` : file.file_name}
                                    </div>
                                    <div>
                                        <button type="button" onClick={() => handleOpen(file.id)} className="ml-4 bg-transparent font-semibold">Ver archivo</button>
                                        <FilePreview
                                            key={file.id}
                                            file={file.file_url}
                                            source="cloud"
                                            handleOpen={() => handleOpen(file.id)}
                                            open={openModalId === file.id}
                                            handleClose={handleClose}
                                        />
                                    </div>
                                    { (!formik.values.idOfFilesToBeDeleted.includes(file.id)) ? (
                                        <button
                                            type="button" 
                                            className="ml-2 ml-2 flex items-center justify-center"
                                            onClick={() => {formik.setFieldValue('idOfFilesToBeDeleted', [...formik.values.idOfFilesToBeDeleted, file.id]);
                                                console.log('idoffilestobedeleted',formik.values.idOfFilesToBeDeleted);
                                            }}
                                        >
                                            <DeleteIcon/>
                                        </button>
                                    ) : (
                                        <button
                                            type="button"
                                            className="ml-2 bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded"
                                            onClick={() => {
                                                const updatedFileIds = formik.values.idOfFilesToBeDeleted.filter(id => id !== file.id);
                                                formik.setFieldValue('idOfFilesToBeDeleted', updatedFileIds);
                                            }}
                                        >
                                                Deshacer eliminación
                                        </button>
                                    )}
                                </div>
                            ))
                        }

                        {/* Si existe la solicitud y se está editando: */}
                        { requestData ? (
                            <div>
                                {/* { !expense.id ? ( */}
                                    {/* <div> */}
                                        { localFiles.filter((fileObject, i) => fileObject.expenseId === expense.id).map((file: any, fileIndex: number) => (
                                            <div key={file.index} className="my-2 mb-2 gap-2">
                                                <div className="flex justify-left items-center gap-2 mb-2">
                                                    <label
                                                        className="text-sm text-center bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-2 rounded font-semibold cursor-pointer"
                                                    >
                                                        {file && file.file.name && (file.file.name.length > 20 ? `${file.file.name.slice(0, 20)}...` : file.file.name)}
                                                    </label>
                                                    <div>
                                                        { (file && file.file.name) && (
                                                            <div>
                                                                <button type="button" onClick={() => handleOpen(file.index)} className="ml-4 bg-transparent font-semibold">
                                                                    Ver archivo
                                                                </button>
                                                                <FilePreview
                                                                key={file.index}
                                                                file={file.file}
                                                                source="local"
                                                                handleOpen={() => handleOpen(file.index)}
                                                                open={openModalId === file.index}
                                                                handleClose={handleClose}
                                                            />
                                                        </div>
                                                    )}
                                                    </div>
                                                {file.index >= 0 && (
                                                    <button
                                                        type="button"
                                                        className="ml-2 flex items-center justify-center"
                                                        onClick={() => removeLocalFile(file.index)}
                                                    >
                                                        <DeleteIcon/>
                                                    </button>
                                                    )}
                                                </div>
                                            </div>
                                        ))}

                                        {activatedAddFileToExpense.includes(expense.id) && (
                                            <input
                                                type="file"
                                                className="mt-2 mb-2"
                                                onChange={(event) => {
                                                    if (event.target.files && event.target.files[0]) {
                                                        const actualNumberOfFiles = localFiles.length;
                                                        console.log(expense);
                                                        addLocalFile(event, expense.id, actualNumberOfFiles);
                                                        setActivatedAddFileToExpense(activatedAddFileToExpense.filter((arrayExpense: any) => arrayExpense !== expense.id));
                                                    }
                                                }}
                                                accept="application/pdf,image/*"
                                            />
                                        )}

                                        <button
                                            type="button"
                                            onClick={() => {
                                                setActivatedAddFileToExpense([...activatedAddFileToExpense, expense.id]);
                                            }}
                                            className="text-sm bg-gray-600 hover:bg-gray-800 text-white font-bold py-2 px-4 rounded"
                                        >
                                            Agregar archivo
                                        </button>
                                    </div>
                                /*{/* ) : (
                                    <div>
                                        <FieldArray name={`particular_expenses[${index}].files`}>
                                            {({ push, remove: removeFile }) => (
                                                <div>
                                                    {expense.files.map((file: any, fileIndex: number) => (
                                                        <div key={fileIndex} className="my-2 mb-2 gap-2">
                                                            <input
                                                                type="file"
                                                                className="hidden"
                                                                id={`particular_expenses[${index}].files[${fileIndex}]`}
                                                                name={`particular_expenses[${index}].files[${fileIndex}]`}
                                                                onChange={(event) => {
                                                                    const file = event.currentTarget.files ? event.currentTarget.files[0] : null;
                                                                    formik.setFieldValue(`particular_expenses[${index}].files[${fileIndex}]`, file);
                                                                }}
                                                                accept="application/pdf,image/*"
                                                            />
                                                            <div className="flex justify-left items-center gap-2 mb-2">
                                                                <label
                                                                    htmlFor={`particular_expenses[${index}].files[${fileIndex}]`}
                                                                    className="text-sm text-center bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-2 rounded font-semibold cursor-pointer"
                                                                >
                                                                    {file ? (file.name.length > 20 ? `${file.name.slice(0, 20)}...` : file.name) : 'Subir documento'}
                                                                </label>
                    
                                                                <div>
                                                                    { (file && file.name) && (
                                                                        <div>
                                                                            <button type="button" onClick={() => handleOpen(file.name)} className="ml-4 bg-transparent font-semibold">
                                                                                Ver archivo
                                                                            </button>
                                                                            <FilePreview
                                                                                key={formik.values.particular_expenses[index].files[fileIndex].name}
                                                                                file={formik.values.particular_expenses[index].files[fileIndex]}
                                                                                source="local"
                                                                                handleOpen={() => handleOpen(file.name)}
                                                                                open={openModalId === file.name}
                                                                                handleClose={handleClose}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                    
                                                                {fileIndex >= 0 && (
                                                                    <button
                                                                        type="button"
                                                                        className="ml-2 flex items-center justify-center"
                                                                        onClick={() => removeFile(fileIndex)}
                                                                    >
                                                                        <DeleteIcon/>
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ))}
                                                    <button
                                                        type="button"
                                                        onClick={() => push(null)}
                                                        className="text-sm bg-grey-800 hover:bg-grey-900 text-black font-bold py-2 px-4 rounded"
                                                    >
                                                        Agregar archivo
                                                    </button>
                                                </div>
                                            )}
                                        </FieldArray>
                                    </div>
                                )}
                            </div> *//*}*/
                        ) : (
                            <div>
                                <FieldArray name={`particular_expenses[${index}].files`}>
                                    {({ push, remove: removeFile }) => (
                                        <div>
                                            {expense.files.map((file: any, fileIndex: number) => (
                                                <div key={fileIndex} className="my-2 mb-2 gap-2">
                                                    <input
                                                        type="file"
                                                        className="hidden"
                                                        id={`particular_expenses[${index}].files[${fileIndex}]`}
                                                        name={`particular_expenses[${index}].files[${fileIndex}]`}
                                                        onChange={(event) => {
                                                            const file = event.currentTarget.files ? event.currentTarget.files[0] : null;
                                                            formik.setFieldValue(`particular_expenses[${index}].files[${fileIndex}]`, file);
                                                        }}
                                                        accept="application/pdf,image/*"
                                                    />
                                                    <div className="flex justify-left items-center gap-2 mb-2">
                                                        <label
                                                            htmlFor={`particular_expenses[${index}].files[${fileIndex}]`}
                                                            className="text-sm text-center bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-2 rounded font-semibold cursor-pointer"
                                                        >
                                                            {file ? (file.name.length > 20 ? `${file.name.slice(0, 20)}...` : file.name) : 'Subir documento'}
                                                        </label>
                                                        {!file ? (
                                                                renderFormikError('Debe subir un documento')
                                                            ) : null}
                                                        <div>
                                                            { (file && file.name) && (
                                                                <div>
                                                                    <button type="button" onClick={() => handleOpen(file.name)} className="ml-4 bg-transparent font-semibold">
                                                                        Ver archivo
                                                                    </button>
                                                                    <FilePreview
                                                                        key={formik.values.particular_expenses[index].files[fileIndex].name}
                                                                        file={formik.values.particular_expenses[index].files[fileIndex]}
                                                                        source="local"
                                                                        handleOpen={() => handleOpen(file.name)}
                                                                        open={openModalId === file.name}
                                                                        handleClose={handleClose}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
            
                                                        {fileIndex >= 0 && (
                                                            <button
                                                                type="button"
                                                                className="ml-2 flex items-center justify-center"
                                                                onClick={() => {removeFile(fileIndex);
                                                                    console.log('removeFile');
                                                                }}
                                                            >
                                                                <DeleteIcon/>
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                            <button
                                                type="button"
                                                onClick={() => push(null)}
                                                /*onClick={() => {
                                                    setActivatedAddFileToExpense([...activatedAddFileToExpense, expense.id]);
                                                }}*/
                                                className="text-sm bg-gray-600 hover:bg-gray-800 text-white font-bold py-2 px-4 rounded"
                                            >
                                                Agregar archivo
                                            </button>
                                        </div>
                                    )}
                                </FieldArray>
                            </div>
                        )}
                        </div>
                        <div className="md:col-span-1 ml-4 mt-5 mb-5">
                            <button
                                type="button"
                                onClick={() => {
                                    if (expense.status === 'new') {
                                        remove(index);
                                    } else {
                                        formik.setFieldValue(`particular_expenses[${index}].status`, 'deleted');
                                    }
                                }}
                                className="text-white w-1/4 bg-red-600 hover:bg-red-700 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2 my-1 text-center"
                            >
                                Eliminar gasto
                            </button>
                        </div>
                        </div>
                    </div>
                    ))}
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-4 items-center mb-5">
                        <div>
                            <button
                                type="button"
                                onClick={() => {
                                    const newExpense = {
                                        id: nextId,
                                        note: '',
                                        document_type: '',
                                        document_number: '',
                                        business_name: '',
                                        business_rut: '',
                                        category_id: 0,
                                        benefit_center_prc_code: '',
                                        project_number: 'No tiene número de proyecto',
                                        amount: 0,
                                        exchange_rate: 0,
                                        clp_amount: 0,
                                        currency_id: 1,
                                        expense_date: dayjs().format('YYYY-MM-DD'),
                                        files: [],
                                        status: 'new'
                                    };
                                    //setHasNoProjectNumber(false);
                                    setNextId(nextId + 1);
                                    formik.setFieldValue(`particular_expenses`, [...formik.values.particular_expenses, newExpense]);
                                }}
                                className="mt-4 text-white w-full bg-blue-400 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                            >
                                Agregar gasto
                            </button>
                        </div>
                        <div>

                        </div>
                    </div>
                  </div>
                )}
              </FieldArray>
              <h1 className="font-bold mb-5 text-lg text-gray-800"> Para el cálculo del saldo </h1>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center mb-5">
                <div>
                    {(formik.values.expense_request_type !== 'Solicitud de reembolso') ?
                        (<div>
                            <label htmlFor="assigned_funds" className="block text-gray-700 text-sm font-semibold mr-5 mb-5">
                                Monto del fondo asignado <span className="italic">(requerido) </span> (CLP)
                            </label>
                            <input
                                className="shadow border rounded w-full text-center py-1 px-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="assigned_funds"
                                name="assigned_funds"
                                type="number"
                                value={formik.values.assigned_funds}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.assigned_funds && formik.errors.assigned_funds ? (
                                renderFormikError(formik.errors.assigned_funds)
                            ) : null}
                        </div>) : (null)
                    }
                </div>
                <div></div>
                <div>
                    <label htmlFor="assigned_funds" className="block text-gray-700 text-sm font-semibold mr-5 mb-5">
                        Total rendición <span className="italic text-gray-700 text-sm">(monto que se repone en el caso de fondos rotatorios)</span>
                    </label>
                                
                    <p className="shadow border rounded w-full text-center py-1 px-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                        {sumOfAmounts(formik)} CLP
                    </p>
                </div>

                <div></div>

                <div>
                    <label htmlFor="total_balance" className="block text-gray-700 text-sm font-semibold mr-5 mb-5">
                        Total saldo
                    </label>
                                
                    <p className="shadow border rounded w-full text-center py-1 px-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                        {formik.values.assigned_funds - sumOfAmounts(formik)} CLP
                    </p>
                </div>
                
                <div></div>

                <div>
                    <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="in_favor_of_solicitant">
                        ¿Es a favor del solicitante? <span className="italic">(requerido)</span>
                    </label>
                    <select
                        className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="in_favor_of_solicitant"
                        name="in_favor_of_solicitant"
                        value={String(formik.values.in_favor_of_solicitant)}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    >
                        <option value="">Selecciona una opción.</option>
                            {inFavorOfSolicitantOptions.map((option: any) => (
                                <option key={option.id} value={option.id}>
                                    {option.label}
                                </option>
                            ))}
                    </select>
                    {formik.touched.in_favor_of_solicitant && formik.errors.in_favor_of_solicitant ? (
                        renderFormikError(formik.errors.in_favor_of_solicitant)
                    ) : null}
                </div>

                {formik.values.in_favor_of_solicitant ? (<div></div>) : (null)}
                {formik.values.in_favor_of_solicitant ? (
                    <div>
                            <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="beneficiary_name">
                                Nombre del beneficiario (a) <span className="italic">(requerido)</span>
                            </label>
                            <input
                                className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="beneficiary_name"
                                name="beneficiary_name"
                                value={formik.values.beneficiary_name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.beneficiary_name && formik.errors.beneficiary_name ? (
                                renderFormikError(formik.errors.beneficiary_name)
                            ) : null}
                    </div>) : (null)
                }

                {formik.values.in_favor_of_solicitant ? (
                    <div>
                            <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="beneficiary_rut">
                                RUT del beneficiario (a) <span className="italic">(requerido)</span>
                            </label>
                            <input
                                className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="beneficiary_rut"
                                name="beneficiary_rut"
                                value={formik.values.beneficiary_rut}
                                onChange={(e: any) => {
                                    const formattedRUT = formatRUT(e.target.value);
                                    formik.setFieldValue(`beneficiary_rut`, formattedRUT);
                                }}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.beneficiary_rut && formik.errors.beneficiary_rut ? (
                                renderFormikError(formik.errors.beneficiary_rut)
                            ) : null}
                    </div>) : (null)
                }
                
                {formik.values.in_favor_of_solicitant ? (
                        <div>
                            <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="beneficiary_current_account_number">
                                Cuenta corriente del beneficiario (a) <span className="italic">(requerido)</span>
                            </label>
                            <input
                                className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="beneficiary_current_account_number"
                                name="beneficiary_current_account_number"
                                value={formik.values.beneficiary_current_account_number}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.beneficiary_current_account_number && formik.errors.beneficiary_current_account_number ? (
                                renderFormikError(formik.errors.beneficiary_current_account_number)
                            ) : null}
                    </div>) : (null)
                }

                {formik.values.in_favor_of_solicitant ? (
                        <div>
                            <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="beneficiary_bank">
                                Banco del beneficiario (a) <span className="italic">(requerido)</span>
                            </label>
                            <input
                                className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="beneficiary_bank"
                                name="beneficiary_bank"
                                value={formik.values.beneficiary_bank}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.beneficiary_bank && formik.errors.beneficiary_bank ? (
                                renderFormikError(formik.errors.beneficiary_bank)
                            ) : null}
                    </div>) : (null)
                }
              </div>
                <div className="grid grid-cols-1 md:grid-cols-4 gap-4 items-center mb-5">
                    <div></div>
                    <div></div>
                    {(
                        (formik.values.status === 'Borrador' || formik.values.status === '' || requestData?.status === 'Borrador') ? (
                            <div>
                                <button 
                                    type="submit"
                                    onClick={() => formik.setFieldValue('status', 'Borrador')}
                                    className="mt-4 w-full text-white bg-customBlue hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center flex-end"
                                    data-tooltip-content="" data-tooltip-id="borradorTooltip">
                                    Guardar como borrador
                                </button>
                                <Tooltip id="borradorTooltip" place="top" content="Al enviar la solicitud, no podrás modificarla." afterShow={() => handleSetTouchedAll(formik.setTouched, formik.initialValues)} />
                            </div>
                        ) : 
                        (<div></div>)
                    )}

                    <div>
                        <button
                            type="submit"
                            disabled={formik.isValidating || !formik.isValid || formik.isSubmitting}
                            onClick={() => {
                                formik.setFieldValue('status', 'Enviado');
                                handleSetTouchedAll(formik.setTouched, formik.values);
                            }}
                            className="mt-4 w-full text-white bg-customBlue hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center flex-end"
                            data-tooltip-content="" data-tooltip-id="submitTooltip">
                            { requestData ? 'Actualizar solicitud' : 'Enviar solicitud' }
                        </button>
                        <Tooltip id="submitTooltip" place="top" content="Al enviar la solicitud, no podrás modificarla." afterShow={() => handleSetTouchedAll(formik.setTouched, formik.values)} />
                    </div>
                </div>
                
            </Form>
          </FormikProvider>
        )}
      </Formik>
    )
}

function getError(formik: any, path: any) {
    const error = _.get(formik.errors, path);
    const touched = _.get(formik.touched, path);
    if (touched && typeof error === 'string') {
        return <div className="text-red-500 text-sm">{error}</div>;
    }
    return null;
}

function sumOfAmounts(formik: any) {
    return formik.values.particular_expenses.reduce((accumulator: any, currentExpense: any) => {
      const amount = Number(currentExpense.amount) || 0;
      return accumulator + amount;
    }, 0);
}


export default ExpenseReportRequestForm;
