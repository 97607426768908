import React, { useState, useEffect } from 'react';
import performApiRequest from '../../utils/performApiRequest';
import { useAuthStatus } from '../../hooks/useAuthStatus';
import { useDataForEndpoint } from '../../context/dataProvider';
import Loader from '../../elements/loader';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import { SystemRole } from '../../types/fields';
import { API_ENDPOINTS } from '../../utils/endpoints';
import { useToast } from '../../context/useToast';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';

interface User {
  id: string;
  names: string;
  ap_pat: string;
  ap_mat: string;
  rut: string;
  dv_rut: string;
  position: number;
}

interface AdministratorPanelProps {
  selectedRoleId?: string;
  selectedRoleSystem?: string;
  selectedRoleName?: string;
  selectedRoleDescription?: string;
}

export default function PermissionsAdministratorPanel({ selectedRoleId, selectedRoleSystem, selectedRoleName, selectedRoleDescription }: AdministratorPanelProps) {
  const isAuthenticated = useAuthStatus();
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const systemRoles = useDataForEndpoint<SystemRole[]>('system_roles');
  const [availableUsers, setAvailableUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { showSuccessToast, showErrorToast } = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsersData = async () => {
      try {
        const requestOptions = {
          method: 'GET',
        };
        const response = await performApiRequest(`/v1/users/getUsersInfo`, requestOptions);
        setAvailableUsers(response.data.usersData);
      } catch (error) {
        console.error('Error fetching user data', error);
      }
    };
    fetchUsersData();
  }, [isAuthenticated]);

  useEffect(() => {
    const allData = [
      systemRoles,
    ];
    
    if (isAuthenticated) {
        const isAnyDataUndefined = allData.some(data => data === undefined);
        setIsLoading(isAnyDataUndefined);
    } // Set loading to false if all data is loaded
  }, [systemRoles, isAuthenticated]);

    useEffect(() => {
        if (selectedRoleId) {
            fetchUsersByRoleData();
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRoleId]);

    const userOptions = availableUsers.map(user => ({
        value: user.id,
        label: `${user.names} ${user.ap_pat}. RUT: ${user.rut}-${user.dv_rut}`,
    }));
    
    const fetchUsersByRoleData = async () => {
        setIsLoading(true);
        const requestOptions = {
            method: 'GET'
        };
        try {
            const response = await performApiRequest(
                `${API_ENDPOINTS.getUsersWithRole}/${selectedRoleId}`,
                requestOptions
            );
            if (response.status === 200) {
                    const formattedUsers = response.data.users.map((user: User) => ({
                        ...user,
                    }))
                    setSelectedUsers(formattedUsers);
            } else if (response.status === 204) {
                    setSelectedUsers([]);
                }
        } catch (error) {
            console.error('Failed to fetch roles:', error);
        }
        setIsLoading(false);
    };

        const handleAddUser = (userId: string) => {
            const userToAdd = availableUsers.find(user => Number(user.id) === Number(userId));
            if (userToAdd && !selectedUsers.some(u => Number(u.id) === Number(userId))) {
                const newUser = {
                    ...userToAdd,
                };
                setSelectedUsers([...selectedUsers, newUser]);
            }
            setAvailableUsers(prevUsers => prevUsers.filter(user => Number(user.id) !== Number(userId)));
        };

        const handleDeleteUser = (userId: string) => {
            setSelectedUsers(selectedUsers.filter(user => user.id !== userId));
        };

    const handleSubmit = async () => {
        if (!selectedRoleId) {
            alert("Debes seleccionar un permiso.");
            return;
        }
    
        if (selectedUsers.length === 0) {
            alert("Debes seleccionar al menos a un usuario.")
        }
    
        const payload = {
            roleId: selectedRoleId,
            selectedUsers
        };

        //console.log('payload: ', payload);

        try {
            const requestOptions = {
                method: 'POST',
                data: payload
            }
            const response = await performApiRequest(API_ENDPOINTS.addUsersToRole, requestOptions);
            if (response.status === 201) {
                showSuccessToast('Los datos se actualizaron con éxito.')
            }
            } catch (error: any) {
                showErrorToast(`Error: ${error.message}`);
            }
    };

    if (isLoading) {
        return <Loader/>
    }

    return (
        <div className="flex flex-col items-center justify-center">
            <div className="flex flex-col justify-center items-center mb-4">
                <h2 className="font-bold mb-2 text-lg text-center"> Rol: {selectedRoleSystem} - {selectedRoleName} </h2>
                <h2 className="font-semibold mb-2 text-md mx-10"> {selectedRoleDescription} </h2>
            </div>
            <div className="flex flex-col justify-center items-center mb-4">
                <h3 className="font-semibold mb-2"> Selecciona usuarios para asignarles el rol de  {`${selectedRoleName}`}. </h3>
                <Select
                options={userOptions}
                onChange={(selectedOption: any) => handleAddUser(selectedOption.value)}
                placeholder="Selecciona o busca un usuario..."
                isClearable
                isSearchable
                className="min-w-64"
                />
            </div>
            {selectedUsers.map((user, index) => (
                <div key={index} className="w-full grid grid-cols-1 md:grid-cols-2 gap-2 my-2 justify-center items-center text-center flex flex-row bg-beige py-2 px-4 user">
                    <div>
                        {user.names} {user.ap_pat} {user.ap_mat}. <br/> RUT: {user.rut}-{user.dv_rut}
                    </div>
                    <div> 
                        <button
                            className="ml-5"
                            onClick={() => handleDeleteUser(user.id)}>
                            <PersonRemoveIcon/>
                        </button>
                    </div>
                </div>
            ))}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <button
                    onClick={() => navigate('/')}
                    className={`submit-button py-2 px-5 rounded-md bg-red-600 hover:bg-red-800 text-white mt-5`}>
                    Volver
                </button>
                <button
                    onClick={handleSubmit}
                    disabled={selectedUsers.length < 1}
                    className={`submit-button py-2 px-5 rounded-md bg-blue-400 hover:bg-blue-600 text-white mt-5 ${selectedUsers.length < 1 ? 'bg-grey-800':'bg-blue-400'}`}>
                    Guardar
                </button>
            </div>
        </div>
    );
}
